function OngoingLearningIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 3.75h-2.25v9.5H22v1.5h-9.25v.94l4.78 4.78-1.06 1.06-3.72-3.72v3.94h-1.5v-3.94l-3.72 3.72-1.06-1.06 4.78-4.78v-.94H2v-1.5h2.25v-9.5H2v-1.5h20v1.5zm-16.25 0h12.5v9.5H5.75v-9.5z"
        fill="#9F9F9F"
      />
    </svg>
  );
}

export default OngoingLearningIcon;
