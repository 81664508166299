import ConfemyLogo from '../../assets/2.png';
import CreditsIcon from '../icons/CreditsIcon';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';
import {
  getFormattedDateInTz,
  getFormattedDateInTz2,
  getLocationString,
} from '../../utility/commonUtil';

export default function ConferenceReceiptPrint({ bookingDetails }) {
  return (
    <div className="p-12">
      <div
        // ref={componentRef}
        className="grid-table-wrap"
        style={{
          border: '.5px solid #ced9de',
          borderRadius: 4,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
          padding: '24px 4px 0px 4px',
        }}
      >
        <div className="p-24 mb-8">
          <div className="flex-vc mb-24 mr-4">
            <img className="icon-lg" src={ConfemyLogo} />
            <h4 className="mt-4 ml-4">Confemy Booking Details</h4>
          </div>
          {bookingDetails?.ticket?.isCourse && (
            <div
              className="conf-course-tag conference-course-tag-text mb-8"
              style={{
                backgroundColor: '#ccffff',
              }}
            >
              <p>Pre/Post Course</p>
            </div>
          )}

          {bookingDetails?.ticket?.isCourse ? (
            <h4 className="mb-12">
              {bookingDetails?.ticket?.name
                ? bookingDetails?.ticket?.title
                : 'Title'}
            </h4>
          ) : (
            <h4 className="mb-12">
              {bookingDetails?.conference
                ? bookingDetails?.conference?.title
                : 'Title'}
            </h4>
          )}
          <div className="caption-2-regular-gray3 preview-text mb-16">
            <p>
              <span className="caption-1-heavy-cblack">Booking Date : </span>
              <span>
                {' '}
                {getFormattedDateInTz2(
                  bookingDetails?.bookingDate,
                  bookingDetails?.timezone
                )}
              </span>
            </p>
          </div>
          <div className="mb-12">
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-sm mr-12" />
              {bookingDetails?.ticket?.isCourse ? (
                <span className="caption-2-regular-gray3">
                  {getFormattedDateInTz(
                    bookingDetails?.ticket?.startDate,
                    bookingDetails?.conference?.timezone
                  )
                    ? `Start : ${getFormattedDateInTz(
                        bookingDetails?.ticket?.startDate,
                        bookingDetails?.conference?.timezone
                      )}`
                    : `Ongoing event`}
                </span>
              ) : (
                <span className="caption-2-regular-gray3">
                  {getFormattedDateInTz(
                    bookingDetails?.conference?.startDate,
                    bookingDetails?.conference?.timezone
                  )
                    ? `Start : ${getFormattedDateInTz(
                        bookingDetails?.conference?.startDate,
                        bookingDetails?.conference?.timezone
                      )}`
                    : `Ongoing event`}
                </span>
              )}
            </div>
            <div className="flex-vc  mb-6">
              <DateIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {getFormattedDateInTz(
                  bookingDetails?.conference?.endDate,
                  bookingDetails?.conference?.timezone
                )
                  ? `End : ${getFormattedDateInTz(
                      bookingDetails?.conference?.endDate,
                      bookingDetails?.conference?.timezone
                    )}`
                  : `Ongoing event`}
              </span>
            </div>
            <div className="flex-vc  mb-6">
              <LocationIcon className="icon-sm mr-12" />
              <span className="caption-2-regular-gray3">
                {' '}
                {getLocationString(
                  bookingDetails?.conference?.mode,
                  bookingDetails?.conference?.city
                )}
              </span>
            </div>

            <div>
              <p className="mb-8">
                <span className="caption-1-heavy-cblack">Status: </span>
                {bookingDetails?.ticketBooked === 1 ? (
                  <span
                    style={{
                      color: '#4CB944',
                    }}
                    className="caption-2-regular-gray3"
                  >
                    Booking Confirmed
                  </span>
                ) : (
                  <span
                    style={{ color: '#bb2124' }}
                    className="caption-2-regular-gray3"
                  >
                    Cancelled
                  </span>
                )}
              </p>
            </div>
            <div className="caption-2-regular-gray3 preview-text mb-16">
              <p className="mb-8">
                <span className="caption-1-heavy-cblack">
                  Booking number :{' '}
                </span>
                <span>{bookingDetails?.serialNumber} </span>
              </p>
              <p>
                <span className="caption-1-heavy-cblack">Booking Date : </span>
                <span>
                  {' '}
                  {getFormattedDateInTz2(
                    bookingDetails?.bookingDate,
                    bookingDetails?.timezone
                  )}
                </span>
              </p>
            </div>
            <div className="mb-12">
              <div className="flex-vc mb-8">
                <CreditsIcon className="icon-sm mr-12" />
                <span className="caption-2-regular-gray3">
                  {bookingDetails?.media?.credits?.length > 0
                    ? `${bookingDetails?.conference?.credits[0]?.quantity} credits`
                    : 'Credits not offered'}
                </span>
              </div>
            </div>
            <p>
              <span className="caption-1-heavy-cblack">
                Total Booking Amount:{' '}
                {bookingDetails?.price === 0
                  ? 'FREE'
                  : `${bookingDetails?.conference?.currencySymbol} ${bookingDetails?.price}`}
              </span>
            </p>
          </div>

          <div>
            <div className=" mb-18 mt-16">
              <div className="abd-grid-item mt-16 ">
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">First Name: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.firstName}
                  </span>
                </p>
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">Last Name: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.lastName}
                  </span>
                </p>
                <p className="mb-8">
                  <span className="caption-1-heavy-cblack">Email: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.user?.email}
                  </span>
                </p>

                <p className="caption-2-regular-gray3 mb-8">
                  <span className="caption-1-heavy-cblack"> Price: </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.originalPrice > 0
                      ? `${bookingDetails?.conference?.currencySymbol}`
                      : null}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {' '}
                    {bookingDetails?.originalPrice > 0
                      ? bookingDetails?.originalPrice
                      : 'FREE'}
                  </span>
                </p>
                <p className="caption-2-regular-gray3 mb-4">
                  <span className="caption-1-heavy-cblack">
                    Service Charges:{' '}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.convenienceFee > 0
                      ? `${bookingDetails?.conference?.currencySymbol} `
                      : null}
                  </span>
                  <span className="caption-2-regular-gray3">
                    {bookingDetails?.convenienceFee}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
