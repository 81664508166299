import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import api from "../../utility/api";
import Textarea from "../formik/Textarea";
import TextError from "../formik/TextError";
import SelectFormType1 from "../reselect/SelectFormType1";
import { alertAction } from "../../redux/alert/alertAction";
import "../feedback/feedback.styles.scss";

const initialValues = {
  issueType: "",
  issue: "",
};

const validationSchema = yup.object({
  issueType: yup.string().required("Required"),
  issue: yup.string().required("Required"),
});

export default function ReportAbuseForm({ data, setShowReportAbuseForm }) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      issueDetails: {
        userId: user?._id,
        issueType: values.issueType,
        issue: values.issue,
      },
    };
    try {
      const url = `/media/${data?._id}/abuse/reports?type=VIDEO`;
      const response = await api.post(url, formData);
      if (response) {
        setShowReportAbuseForm(false);
        dispatch(alertAction(response.data.message, "success"));
        actions.resetForm({ values: initialValues });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError(
          "content",
          err?.response?.data?.message || "Something went wrong"
        );
      }
    }
  };

  return (
    <div className="feedback-container">
      <div className="mb-24">
        <h2 className="color-primary mb-24">Report Abuse</h2>
        <p className="body-regular-gray3 mb-16">
          Flagged content is reviewed by Confemy staff to determine whether it
          violates Terms of Service or Community Guidelines.
        </p>
        <p className="body-regular-gray3">
          Please submit your issue using following form.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { isSubmitting, resetForm } = props;
          return (
            <Form className="form-type-1">
              <div className="mb-28">
                <h4>Issue Type*</h4>
                <div className="material-textfield mt-8 mb-8">
                  <SelectFormType1
                    options={[
                      {
                        label: "Inappropriate Course Content",
                        value: "Inappropriate Course Content",
                      },
                      {
                        label: "Inappropriate Behaviour",
                        value: "Inappropriate Behaviour",
                      },
                      {
                        label: "Confemy Policy Voilation",
                        value: "Confemy Policy Voilation",
                      },
                      {
                        label: "Spammy Content",
                        value: "Spammy Content",
                      },
                      {
                        label: "Other",
                        value: "Other",
                      },
                    ]}
                    label="Issue Type"
                    name="issueType"
                    placeholder="Select an issue*"
                    value={props.values.issueType || ""}
                    onChange={(value) => {
                      props.setFieldValue("issueType", value.value);
                    }}
                  />
                </div>
                <div>
                  {props.touched.issue && Boolean(props.errors.issue) && (
                    <TextError>{props.errors.issue}</TextError>
                  )}
                </div>
                <div>
                  <h4>Issue Details*</h4>
                  <Textarea
                    rows={15}
                    id="content"
                    name="issue"
                    // label="Feedback content*"
                    placeholder="Write issue here..."
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                  />
                </div>
              </div>
              <div style={{ width: "100%", maxWidth: "464px" }}>
                <SubmitCancelButtonWithLoader
                  isSubmitting={isSubmitting}
                  onCancel={() => resetForm({ values: initialValues })}
                  cancelButtonClass="button button-green"
                  fullWidth={true}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
