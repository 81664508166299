import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DateIcon from '../icons/DateIcon';
import LocationIcon from '../icons/LocationIcon';

import NoImage from '../../assets/No-Image-Found-400x264.png';
import { addPotenitalUser, openInNewTab } from '../../utility/commonUtil';

import './upcomingConfCard.scss';

export default function UpcomingConfCard({
  data,

  setConferenceId,
  potentialUserForm,
}) {
  const user = useSelector((state) => state.auth.user);

  // const startDateObj = new Date(data?.conference?.startDate);
  // const endDateObj = new Date(data?.conference?.endDate);

  // const formattedStartDate = startDateObj.toString()
  //   ? formatInTimeZone(
  //       startDateObj,
  //       data?.conference?.timezone,
  //       'MMM-dd-yyyy, HH:mm aa',
  //       { locale: enGB }
  //     )
  //   : '';

  // const formattedEndDate = endDateObj
  //   ? formatInTimeZone(
  //       endDateObj,
  //       data?.conference?.timezone,
  //       'MMM-dd-yyyy, HH:mm aa',
  //       { locale: enGB }
  //     )
  //   : '';

  const getLocationString = () => {
    let locationStrig = 'Location';
    if (data?.mode?.length > 0) {
      if (data?.mode?.includes('venue') && data?.location) {
        locationStrig = data?.location;
      }

      if (data?.mode?.includes('onlineConf')) {
        locationStrig = 'Online';
      }

      if (data?.mode?.includes('venue') && data?.mode?.includes('onlineConf')) {
        locationStrig = `${data?.location} & Online`;
      }
    }
    return locationStrig;
  };

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        className="upcomingConfs-card mb-16 mt-16"
      >
        <div className="upcoming-banner-container">
          <div
            className="upcoming-conf-img-wrap"
            onClick={() => {
              if (data?.scraped) {
                if (user) {
                  addPotenitalUser(
                    user?.firstName,
                    user?.lastName,
                    user?.email,
                    user?.country,
                    user?.state,
                    user?.city,
                    data?.conference?._id,
                    user?.profession,
                    user?.mobile,
                    user?.countryCode
                  );
                  openInNewTab(data?.url);
                } else {
                  setConferenceId(data?._id);
                  potentialUserForm(true);
                }
              } else {
                if (data?.isCourse) {
                  openInNewTab(`/courses/${data?._id}`);
                } else {
                  openInNewTab(`/search-conference/${data?._id}`);
                }
              }
            }}
          >
            {data?.banner[0] ? (
              <img
                className="sc-conf-img"
                alt="preview"
                src={data?.banner[0]?.Location}
                onError={(e) => {
                  e.currentTarget.src = NoImage;
                }}
                // notFoundSrc="https://placekitten.com/g/200/300"
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  padding: 40,
                  backgroundColor: '#ecf0f2',
                }}
              >
                <div className="text-align-center">
                  <p className="body-bold">Banner comming soon</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <p
            className="caption-2-regular-gray3 mb-8"
            onClick={() => {
              if (data?.scraped) {
                if (user) {
                  addPotenitalUser(
                    user?.firstName,
                    user?.lastName,
                    user?.email,
                    user?.country,
                    user?.state,
                    user?.city,
                    data?.conference?._id,
                    user?.profession,
                    user?.mobile,
                    user?.countryCode
                  );
                  openInNewTab(data?.url);
                } else {
                  setConferenceId(data?._id);
                  potentialUserForm(true);
                }
              } else {
                if (data?.isCourse) {
                  openInNewTab(`/courses/${data?._id}`);
                } else {
                  openInNewTab(`/search-conference/${data?._id}`);
                }
              }
            }}
          >
            {data?.title ? data?.title : 'Conference title'}
          </p>
          {data?.startDate && (
            <div className="flex-vc caption-2-regular-gray3 mb-8 ">
              <DateIcon className="icon-xxs mr-12" />
              <span>
                Start:{' '}
                {formatInTimeZone(
                  new Date(data?.startDate),
                  data?.timezone,
                  'MMM-dd-yyyy, HH:mm aa',
                  { locale: enGB }
                )}
              </span>
            </div>
          )}
          {data?.endDate && (
            <div className="flex-vc caption-2-regular-gray3 mb-8">
              <DateIcon className="icon-xxs mr-12" />
              <span>
                End:{' '}
                {formatInTimeZone(
                  new Date(data?.endDate),
                  data?.timezone,
                  'MMM-dd-yyyy, HH:mm aa',
                  { locale: enGB }
                )}
              </span>
            </div>
          )}
          {data?.mode?.length > 0 && (
            <div className="flex-vc  mb-8">
              <LocationIcon className="icon-xxs mr-12" />
              <span className="caption-2-regular-gray3">
                {getLocationString()}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

UpcomingConfCard.propTypes = {
  data: PropTypes.object.isRequired,
};
