import React, { useEffect, useState } from "react";
import "../video-course/booking-compontents/CourseBooking.scss";
import { useParams, Outlet } from "react-router";
import api from "../../utility/api";
import { useDispatch } from "react-redux";
import { alertAction } from "../../redux/alert/alertAction";
import Loader from "../loader/Loader";
import { loadSinglePodAction } from "../../redux/podcast/podcastAction";
import PodcastTabs from "../tabs/PodcastTabs";
import CourseBookingPageCard from "../cards/CourseBookingPageCard";

export default function PodcastBookingPrice() {
  const mediaId = useParams().courseId;
  const [media, setMedia] = useState({});
  const [episodeCount, setEpisodeCount] = useState({});
  const [sectionCount, setSectionCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const getMediaDetails = async () => {
    try {
      setIsLoading(true);
      let response = await api.get(`/podcast/${mediaId}`);
      dispatch(loadSinglePodAction(response.data.data.media));
      setIsLoading(false);
      setEpisodeCount(response.data.data.episodeCount);
      setSectionCount(response.data.data.sectionCount);
      setMedia(response.data.data.media);
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    getMediaDetails();
  }, [mediaId]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-xl">
          <h1 className="color-primary mb-8">Course Details</h1>
          <div
            className="mb-24"
            style={{
              borderBottom: "1px solid #ced9de",
            }}
          ></div>
          <div className="flex flex-wrap" style={{ gap: 24 }}>
            <div style={{ flexGrow: 1 }}>
              <CourseBookingPageCard
                media={media}
                episodeCount={episodeCount}
                sectionCount={sectionCount}
              />
            </div>
            <div>
              <PodcastTabs
                tabs={[
                  {
                    label: `${media?.currencySymbol} ${media?.monthlyPodcastPrice?.actualPrice} monthly`,
                    path: `/podcast/booking-price/${media?._id}/monthly`,
                  },
                  {
                    label: `${media?.currencySymbol} ${media?.yearlyPodcastPrice?.actualPrice} yearly`,
                    path: `/podcast/booking-price/${media?._id}/yearly`,
                  },
                ]}
                showRadioButtons={false}
              />
              <div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
