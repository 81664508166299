import { useState, Fragment } from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import SubmitCancelButtonWithLoader from "../button/SubmitCancelButtonWithLoader";
import { createVideoCourseAction } from "../../redux/video-course/videoCourseAction";
import TextError from "../formik/TextError";
import { currencylist, getValue } from "../../utility/commonUtil";
import { modalStyleformType4 } from "../reselect/selectUtil";
import CheckTickIcon from "../icons/CheckTickIcon";
import CrossCancelIcon from "../icons/CrossCancelIcon";
import Loader from "../loader/Loader";

const validationSchema = yup.object().shape({
  currency: yup.string("Required").trim().required("Choose a currency"),
  price: yup
    .number()
    .typeError("Enter valid amount")
    .required("Required")
    .min(0, "Price can only be 0 or more"),
  discount: yup
    .number()
    .nullable()
    .typeError("Enter a valid number without % sign")
    .positive("Enter amount more than 0 without % sign")
    .max(100, "Amount can not exceed 100%"),
  coupons: yup.array().of(
    yup.object().shape({
      uuid: yup.string(),
      code: yup
        .string()
        // .matches(websiteRegax, "Enter correct url")
        .required("Required")
        .test(
          "isCreditTest",
          "Save credit using check icon on right",
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      discount: yup
        .number()
        .nullable()
        .required("Required")
        .min(1, "Credit amount can not be less than 1")
        .test(
          "isAmountTest",
          "Save amount using check icon on right",
          function (value) {
            if (
              // this.parent.credits?.find((e) => e.value === value)
              this.parent.isForm
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      isForm: yup.bool(),
    })
  ),
});

export default function VideoCourseStep4() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newVideoCourse = useSelector((state) => state.video.newVideoCourse);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    currency: newVideoCourse?.currency || "",
    price: newVideoCourse?.price || "",
    discount: newVideoCourse?.discount || "",
    coupons: newVideoCourse?.coupons || [],
  };

  async function onSubmit(values, actions) {
    if (!newVideoCourse?.completedStep1) {
      dispatch(alertAction("Complete step-1 first", "danger"));
      return;
    }
    const { currency, price, discount, coupons } = values;

    const isFree = price === 0;

    const formData = {
      mediaDetails: {
        mediaId: newVideoCourse?._id,
        price,
        discount,
        isFree,
        currency,
        coupons,
      },
    };

    //  Submit banner image and add image url to formData object

    try {
      const response = await api.post("courses/videoCourse/step4", formData);

      if (response) {
        dispatch(createVideoCourseAction(response.data.data.media));
        actions.resetForm({ values: initialValues });
        navigate("/dashboard/create-vc/step-5");
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="create-e-inner-wrap">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(props) => {
              console.log(props.values);
              return (
                <form
                  className="form-type-4"
                  autoComplete="off"
                  onSubmit={props.handleSubmit}
                >
                  <p
                    className="body-regular-gray3 mb-24"
                    style={{ color: "#aabdc7" }}
                  >
                    All mandatory fields are makred by *
                  </p>
                  <h2 className="color-primary mb-36">Course Price</h2>
                  <h4 className="avenir-20-500 mb-8">
                    Currency & Course Price *
                  </h4>
                  <p
                    className="caption-1-regular-gray3 mb-16"
                    style={{ color: "#757575" }}
                  >
                    For FREE course, choose your currency and set price as 0
                  </p>
                  <div className="ce-price-grid mb-16">
                    <div>
                      <Select
                        options={currencylist}
                        label="Currency"
                        name="currency"
                        value={getValue(
                          currencylist,
                          props.values.currency,
                          false
                        )}
                        onChange={(value) => {
                          props.setFieldValue("currency", value?.value);
                        }}
                        placeholder="Select Currency *"
                        menuPortalTarget={document.body}
                        styles={modalStyleformType4}
                      />
                      <ErrorMessage name="currency" component={TextError} />
                    </div>
                    <div>
                      <Field
                        type="number"
                        name="price"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Price *"
                      />
                      <ErrorMessage name="price" component={TextError} />
                    </div>
                  </div>
                  <h4 className="avenir-20-500 mb-8">Offer Discount</h4>
                  <p
                    className="caption-1-regular-gray3 mb-16"
                    style={{ color: "#757575" }}
                  >
                    If you want to offer a promotional discount, you can give as
                    a percentage off the course price. Ex. 20%
                  </p>
                  <div className="mb-16">
                    <div className="ce-price-grid">
                      <div
                        className="form-type-4-box"
                        style={{
                          borderColor: "#ced9de",
                        }}
                      >
                        % off
                      </div>
                      <Field
                        type="number"
                        name="discount"
                        min={0}
                        autoComplete="off"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Amount"
                      />
                    </div>
                    <ErrorMessage name="discount" component={TextError} />
                  </div>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">Promo code</h4>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: "#757575" }}
                    >
                      You can release one or multiple promo codes with flat
                      price off with each code. When purchasing the course, a
                      user can apply one code at a time.
                    </p>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="coupons"
                        render={(arrayHelpers) => {
                          const coupons = arrayHelpers.form.values?.coupons;
                          let indxOfLastItem = 0;
                          if (coupons?.length > 0) {
                            indxOfLastItem = coupons?.length - 1;
                          }
                          return (
                            <Fragment>
                              <div
                                // section above add button
                                className="mb-16"
                              >
                                {coupons?.map((coupon, index) => {
                                  return (
                                    <motion.div
                                      // each coupon
                                      className="mb-8"
                                      key={index}
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: "spring",
                                        duration: 0.5,
                                      }}
                                    >
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? "form-type-4-box position-relative"
                                            : "display-none "
                                        }
                                      >
                                        <div>
                                          <span
                                            style={{
                                              paddingRight: "12px",
                                              borderRight: "2px solid #08415c",
                                            }}
                                          >
                                            {coupon?.code}
                                          </span>
                                          <span className="ml-8">
                                            {coupon?.discount}{" "}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            coupon.isForm
                                              ? "display-none"
                                              : "ce-hanging-buttons-wrap"
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: "pointer",
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !coupon.isForm
                                            ? "display-none"
                                            : "ce-points-grid position-relative"
                                        }
                                        // className="ce-points-grid position-relative"
                                      >
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="text"
                                            name={`coupons.${index}.code`}
                                            placeholder="Enter a coupon code"
                                          />
                                          <ErrorMessage
                                            name={`coupons.${index}.code`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div>
                                          <Field
                                            className="dark-placeholder"
                                            type="number"
                                            min={1}
                                            name={`coupons.${index}.discount`}
                                            placeholder="Flat discount amount"
                                          />
                                          <ErrorMessage
                                            name={`coupons.${index}.discount`}
                                            component={TextError}
                                          />
                                        </div>
                                        <div
                                          className={
                                            !coupon.isForm
                                              ? "display-none"
                                              : "ce-hanging-buttons-wrap"
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: "pointer",
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                          <i
                                            style={{ cursor: "pointer" }}
                                            onMouseDown={() => {
                                              props.setFieldTouched(
                                                `coupons.${index}.code`
                                              );
                                              props.setFieldTouched(
                                                `coupons.${index}.discount`
                                              );
                                              props.validateField("coupons");
                                              if (
                                                coupon.code !== "" &&
                                                coupon.discount > 0
                                              ) {
                                                const mycoupons = coupons?.map(
                                                  (item) =>
                                                    item.uuid === coupon.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );
                                                arrayHelpers.form.setFieldValue(
                                                  "coupons",
                                                  mycoupons
                                                );
                                              }
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                    </motion.div>
                                  );
                                })}
                              </div>
                              <div
                                // section for add button
                                // this div controls size of the button
                                style={{
                                  width: "24rem",
                                }}
                              >
                                <motion.button
                                  disabled={
                                    coupons[indxOfLastItem]?.code === "" ||
                                    coupons[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      code: "",
                                      discount: null,
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add coupons
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                    {props.touched.coupons &&
                      Boolean(props.errors.coupons) &&
                      (typeof props.errors.coupons === "string" ? (
                        <TextError>{props.errors.coupons}</TextError>
                      ) : null)}
                  </div>
                  <div className="mb-72">
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      fullWidth={true}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>

          <div className="create-event-res">{/* keep empty */}</div>
        </div>
      )}
    </>
  );
}
