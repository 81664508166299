import { Fragment } from 'react';
import CdSpeakercard from '../speaker/CdSpeakercard';
import BookingCard from './BookingCard';
import LocationIcon from '../icons/LocationIcon';
import {
  capitalize,
  getFormattedDateInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import AddVideoIcon from '../icons/AddVideoIcon';
import { platformOptions } from '../../utility/commonUtil';
import ImgCarousal from '../carousal/ImgCarousal';
import './ConfDetails.scss';

export default function ConfDetails({ conf, preview }) {
  const bookingCard = (
    <BookingCard
      title={conf?.title}
      organizer={conf?.host}
      hostedBy={conf?.hostedBy}
      startDate={conf?.startDate}
      endDate={conf?.endDate}
      timezone={conf?.timezone}
      mode={conf?.mode}
      city={conf?.city}
      credits={conf?.credits}
      currency={conf?.currency}
      basePrice={conf?.basePrice}
      confId={conf?._id}
      bookingTickets={conf?.bookingTickets}
      apiIsLiked={conf?.isLiked}
      preview={preview}
      scraped={conf?.scraped}
      url={conf?.url}
    />
  );

  return (
    <div className="cd-container ">
      <div className="cd-wrapper">
        <div className="cd-1st-col">
          <div className="cd-banner-container">
            {conf?.banner?.length > 0 ? (
              <img
                className="cd-banner"
                src={conf.banner?.[0]?.Location}
                alt="banner"
              />
            ) : (
              <div className="cd-no-banner">
                <div className="text-align-center">
                  <h4>{conf?.title}</h4>
                </div>
              </div>
            )}
          </div>
          <div className="cd-bookingcard-2 cd-bottom-margin ">
            {bookingCard}
          </div>

          {conf?.description && (
            <div className="cd-bottom-margin">
              <h2 className="mb-16 color-primary">About the conference</h2>
              {/* <DisplayQuill apiDelta={conf?.description} /> */}
              <p className="body-regular-gray3">{conf?.description}</p>
            </div>
          )}
          {conf?.mode?.length > 0 && (
            <div className="mb-24">
              <h4 className="color-primary">Location</h4>
            </div>
          )}
          {conf?.mode?.includes('venue') && (
            <div className="cd-bottom-margin">
              <div className="flex mb-24">
                <i className="mr-16">
                  <LocationIcon className="icon-size" />
                </i>
                <h4 className="color-primary">Venue</h4>
              </div>
              <div className="cd-venuecard">
                {conf?.venueImages?.length > 0 && (
                  <ImgCarousal data={conf?.venueImages} />
                )}
                <div className="cd-venuecard-content">
                  <div className="mb-32">
                    <h4 className="color-black mb-24">{conf?.venueName}</h4>
                    <div className="flex">
                      {conf?.venueImages?.length > 0 && (
                        <LocationIcon
                          fill="#c4c4c4"
                          className="icon-size mr-8"
                        />
                      )}

                      <div
                        style={{ fontSize: 16, color: 'black' }}
                        className="body-regular-gray3"
                      >
                        <div>{conf?.street1}</div>
                        <div>{conf?.street2}</div>
                        <div>
                          <span>{conf?.city}, </span>
                          <span>{conf?.state}</span>
                        </div>

                        <div>
                          <span> {conf?.country} </span>
                          <span>{conf?.zipcode}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {conf?.conferenceAmenities?.length > 0 && (
                    <div>
                      <h4 className="color-black mb-24">Amenities</h4>
                      <div className="cd-amenities-grid ">
                        {conf?.conferenceAmenities?.map((amenity) => (
                          <div key={amenity.value} className="flex-vc">
                            <img
                              className="icon-sm mr-8"
                              alt="icon"
                              src={amenity.icon}
                            />
                            <span className="caption-1-regular-cblack">
                              {amenity.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {conf?.liveStreams?.length > 0 && (
            <div className="cd-bottom-margin">
              <div className="flex mb-16">
                <i className="position-relative">
                  <AddVideoIcon className="icon-size" />
                </i>
                <h4 className="color-primary ml-16">
                  Stream live at following links
                </h4>
              </div>
              {conf?.liveStreams?.map((item, indx) => {
                return (
                  <div className="mb-32" key={indx}>
                    <div className="flex-vc">
                      <i className="position-relative pb-6 mr-8">
                        {
                          platformOptions.find(
                            (platform) => platform.value === item.platform
                          )?.icon
                        }
                        {/* <FacebookBlueCircle className="icon-size" /> */}
                      </i>
                      <p className="body-regular-gray3 mb-8">
                        {capitalize(
                          item.platform === 'other'
                            ? item.otherPlatformName
                            : item.platform
                        )}
                      </p>
                    </div>
                    <div className="mb-16">
                      <p
                        style={{ color: '#55a0fa', cursor: 'pointer' }}
                        className="caption-1-regular-gray3"
                        onClick={() => openInNewTab(item.url)}
                      >
                        Click for{' '}
                        {item.platform === 'other'
                          ? item.otherPlatformName
                          : item.platform}{' '}
                        livestream link
                      </p>
                    </div>
                    <p className="body-regular-gray3"> {item.description} </p>
                  </div>
                );
              })}
            </div>
          )}

          {conf?.speakers?.length > 0 && (
            <div className="mb-92">
              <h4 className="mb-40 color-primary">Conference Speakers</h4>
              <div className="cd-speaker-grid">
                {conf?.speakers?.map((speaker) => (
                  <div key={speaker._id}>
                    <CdSpeakercard
                      name={`${speaker.speaker.firstName} ${speaker.speaker.lastName}`}
                      degree={speaker.speaker.degree}
                      designation={speaker.speaker.designation}
                      image={speaker.speaker.images}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {conf?.credits?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-24">Credits</h4>
              <div className="grid-table-wrap">
                <ul className="grid-table-2-300">
                  <li>Type</li>
                  <li>Total credits offered</li>
                  {conf?.credits?.map((credit) => {
                    return (
                      <Fragment key={credit._id}>
                        <li>{credit.creditId.name}</li>
                        <li>{credit.quantity}</li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
          {conf?.tickets?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-24">Tickets</h4>
              <div className="grid-table-wrap">
                <ul className="grid-table-4-600 cd-tickets-grid-table">
                  <li>Ticket</li>
                  <li>Info</li>
                  <li>Available QTY</li>
                  <li>Price</li>
                  {conf?.tickets?.map((ticket) => {
                    return (
                      <Fragment key={ticket._id}>
                        <li>{ticket.name}</li>
                        <li>{ticket.info}</li>
                        <li>{ticket.left}</li>
                        <li>
                          {ticket.currency && ticket.price === 0
                            ? 'FREE'
                            : `${ticket.currency} ${ticket.price}`}
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          {conf?.courses?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-24">Pre/Post Courses</h4>
              <div className="grid-table-wrap">
                <ul className="grid-table-6-600 ">
                  <li>Title</li>
                  <li>Start Date</li>
                  <li>End Date</li>
                  <li>Currency</li>
                  <li>Price</li>
                  <li>Resources</li>
                  {conf?.courses?.map((course) => {
                    return (
                      <Fragment key={course._id}>
                        <li>{course.title}</li>
                        <li>
                          {' '}
                          {getFormattedDateInTz(
                            course?.startDate,
                            conf?.timezone
                          )}
                        </li>
                        <li>
                          {' '}
                          {getFormattedDateInTz(
                            course?.endDate,
                            conf?.timezone
                          )}
                        </li>
                        <li>{course.currency}</li>
                        <li>
                          {course.currency && course.price === 0
                            ? 'FREE'
                            : `${course.currency} ${course.price}`}
                        </li>
                        <li>
                          {course?.resources?.map((c) => {
                            return (
                              <span
                                onClick={() => openInNewTab(c?.Location)}
                                style={{
                                  display: 'inlineBlock',
                                  width: '4ch',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  cursor: 'pointer',
                                  color: '#0000EE',
                                }}
                              >
                                {c?.title}
                              </span>
                            );
                          })}
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          {conf?.refundPolicy && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Refund</h4>
              <p>{conf?.refundDescription}</p>
            </div>
          )}

          {conf?.professions?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Professions</h4>
              <p className="body-regular-gray3">
                {conf.professions?.map((profession, indx) =>
                  indx === conf.professions.length - 1 ? (
                    <span key={profession.label}>{profession.label}</span>
                  ) : (
                    <span key={profession.label}>{profession.label}, </span>
                  )
                )}
              </p>
            </div>
          )}
          {conf?.specialities?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Specialities</h4>
              <p className="body-regular-gray3">
                {conf.specialities?.map((specialities, indx) =>
                  indx === conf.specialities.length - 1 ? (
                    <span key={specialities.label}>{specialities.label}</span>
                  ) : (
                    <span key={specialities.label}>{specialities.label}, </span>
                  )
                )}
              </p>
            </div>
          )}
          {conf?.schedules?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Schedules</h4>
              <p
                style={{
                  color: '#0000EE',
                  cursor: 'pointer',
                }}
                onClick={() => openInNewTab(conf?.schedules[0]?.Location)}
                className="body-regular-gray3"
              >
                {conf?.schedules[0]?.title}
              </p>
            </div>
          )}
          {conf?.courseOutlines?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Course Outline</h4>
              <p
                style={{
                  cursor: 'pointer',
                  color: '#0000EE',
                }}
                onClick={() => openInNewTab(conf?.courseOutlines[0]?.Location)}
                className="body-regular-gray3"
              >
                {conf?.schedules[0]?.title}
              </p>
            </div>
          )}
          {conf?.tags?.length > 0 && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">Tags</h4>
              <p className="body-regular-gray3">
                {conf.tags?.map((tag, indx) =>
                  indx === conf.tags.length - 1 ? (
                    <span key={tag}>
                      <i>{tag}</i>
                    </span>
                  ) : (
                    <span key={tag}>
                      <i>{tag}</i>,{' '}
                    </span>
                  )
                )}
              </p>
            </div>
          )}
          {conf?.mocPointAdded && (
            <div className="cd-bottom-margin">
              <h4 className="color-primary mb-16">MOC Point</h4>
              <p className="body-regular-gray3">{conf?.mocPoint}</p>
            </div>
          )}
        </div>
        <div className="cd-2nd-col">{bookingCard}</div>
      </div>
    </div>
  );
}
