import React from 'react';

function MediaDetailYearAccessIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m10.862 16.06 5.549-5.533-1.06-1.063-4.489 4.477-2.213-2.208-1.06 1.063 3.273 3.263z"
        fill="#08415C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 5.25h3V2h1.5v3.25h10.5V2h1.5v3.25h3v15.5H2.25V5.25zm1.5 14V6.75h16.5v12.5H3.75z"
        fill="#08415C"
      />
    </svg>
  );
}

export default MediaDetailYearAccessIcon;
