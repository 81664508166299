import { formatInTimeZone } from "date-fns-tz";
import enGB from "date-fns/locale/en-GB";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import CreditsIcon from "../icons/CreditsIcon";
import DateIcon from "../icons/DateIcon";
import LikeBlueIcon from "../icons/LikeBlueIcon";
import LocationIcon from "../icons/LocationIcon";
import ShareIcon from "../icons/ShareIcon";
import { useState } from "react";
import NoImage from "../../assets/No-Image-Found-400x264.png";
import ShareModal from "../../components/share-modal/ShareModal";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import { addPotenitalUser, openInNewTab } from "../../utility/commonUtil";

export default function SavedCard({
  data,
  getSaved,
  setConferenceId,
  potentialUserForm,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const unLike = async (confId, userId) => {
    const url = `conferences/like/${confId}/users/${userId}?type=${
      data?.conference?.isCourse ? "COURSE" : "CONF"
    }`;
    try {
      const response = await api.delete(url);
      if (response) {
        getSaved(userId);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const getLocationString = () => {
    let locationStrig = "Location";
    if (data?.conference?.mode?.length > 0) {
      if (
        data?.conference?.mode?.includes("venue") &&
        data?.conference?.location
      ) {
        locationStrig = data?.conference?.location;
      }

      if (data?.conference?.mode?.includes("onlineConf")) {
        locationStrig = "Online";
      }

      if (
        data?.conference?.mode?.includes("venue") &&
        data?.conference?.mode?.includes("onlineConf")
      ) {
        locationStrig = `${data?.conference?.location} & Online`;
      }
    }
    return locationStrig;
  };

  return (
    <>
      <div className="savedconfs-card mb-16">
        <div className="sc-banner-container">
          <div
            className="sc-conf-img-wrap"
            onClick={() => {
              if (data?.conference?.scraped) {
                if (user) {
                  addPotenitalUser(
                    user?.firstName,
                    user?.lastName,
                    user?.email,
                    user?.country,
                    user?.state,
                    user?.city,
                    data?.conference?._id,
                    user?.profession,
                    user?.mobile,
                    user?.countryCode
                  );
                  openInNewTab(data?.conference?.url);
                } else {
                  setConferenceId(data?.conference?._id);
                  potentialUserForm(true);
                }
              } else {
                if (data?.conference?.isCourse) {
                  openInNewTab(`/courses/${data?.conference?._id}`);
                } else {
                  openInNewTab(`/search-conference/${data?.conference?._id}`);
                }
              }
            }}
          >
            {data?.conference?.banner[0] ? (
              <img
                className="sc-conf-img"
                alt="preview"
                src={data?.conference?.banner[0]?.Location}
                onError={(e) => {
                  e.currentTarget.src = NoImage;
                }}
                // notFoundSrc="https://placekitten.com/g/200/300"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: 40,
                  backgroundColor: "#ecf0f2",
                }}
              >
                <div className="text-align-center">
                  <p className="body-bold">Banner comming soon</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <p
            className="savedconfs-title mb-8"
            onClick={() => {
              if (data?.conference?.scraped) {
                if (user) {
                  addPotenitalUser(
                    user?.firstName,
                    user?.lastName,
                    user?.email,
                    user?.country,
                    user?.state,
                    user?.city,
                    data?.conference?._id,
                    user?.profession,
                    user?.mobile,
                    user?.countryCode
                  );
                  openInNewTab(data?.conference?.url);
                } else {
                  setConferenceId(data?.conference?._id);
                  potentialUserForm(true);
                }
              } else {
                if (data?.conference?.isCourse) {
                  openInNewTab(`/courses/${data?.conference?._id}`);
                } else {
                  openInNewTab(`/search-conference/${data?.conference?._id}`);
                }
              }
            }}
          >
            {data.conference?.title
              ? data.conference?.title
              : "Conference title"}
          </p>
          {data?.conference?.startDate && (
            <div className="flex-vc caption-2-regular-gray3 mb-8 ">
              <DateIcon className="icon-xxs mr-12" />
              <span>
                Start:{" "}
                {formatInTimeZone(
                  new Date(data?.conference?.startDate),
                  data?.conference?.timezone,
                  "MMM-dd-yyyy, HH:mm aa",
                  { locale: enGB }
                )}
              </span>
            </div>
          )}
          {data?.conference?.endDate && (
            <div className="flex-vc caption-2-regular-gray3 mb-8">
              <DateIcon className="icon-xxs mr-12" />
              <span>
                End:{" "}
                {formatInTimeZone(
                  new Date(data?.conference?.endDate),
                  data?.conference?.timezone,
                  "MMM-dd-yyyy, HH:mm aa",
                  { locale: enGB }
                )}
              </span>
            </div>
          )}
          {data?.conference?.mode?.length > 0 && (
            <div className="flex-vc  mb-8">
              <LocationIcon className="icon-xxs mr-12" />
              <span className="caption-2-regular-gray3">
                {getLocationString()}
              </span>
            </div>
          )}
          <div className="flex-vc  mb-8">
            <CreditsIcon className="icon-xxs mr-12" />
            <span className="caption-2-regular-gray3">
              {data?.conference?.credits?.length > 0
                ? `${data?.conference?.credits[0]?.quantity} credits`
                : "Credits not added"}
            </span>
          </div>
          <div>
            <span className="caption-2-regular-gray3">
              {data.conference.currency} {data.conference.basePrice} onwords
            </span>
          </div>
          <div className="mt-8"></div>
        </div>
        <div className="flex-vc position-relative">
          <i
            className="mr-12"
            onClick={() => setShowPopUp(!showPopUp)}
            style={{ cursor: "pointer" }}
          >
            <ShareIcon className="icon-size" />
          </i>
          <i
            onClick={() => {
              unLike(data?.conference?._id, user?._id);
            }}
            style={{
              position: "relative",
              paddingTop: 5,
              cursor: "pointer",
            }}
          >
            <LikeBlueIcon className="icon-size" />
          </i>
          {showPopUp && (
            <ShareModal
              setShowPopUp={setShowPopUp}
              showPopUp={showPopUp}
              confId={data?.conference?._id}
              cardClass={"right-share-card"}
              buttonClass={"share-btn"}
            />
          )}
        </div>
      </div>
    </>
  );
}

SavedCard.propTypes = {
  data: PropTypes.object.isRequired,
};
