import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertAction } from '../../redux/alert/alertAction';
import CourseHoverCard from './CourseHoverCard';

import {
  openInNewTab,
  unlikeConference,
  getPrice,
  likeMedia,
} from '../../utility/commonUtil';
import CameraIcon from '../icons/CameraIcon';
import LikeBlueIcon from '../icons/LikeBlueIcon';
import LikeInactiveIcon from '../icons/LikeInactiveIcon';

export default function CourseCard({ data, hideHovCard, tag }) {
  const [isLiked, setIsLiked] = useState(false);

  // const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const dispatch = useDispatch();

  const like = (mediaId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(mediaId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a media.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  useEffect(() => {
    setIsLiked(data?.isLiked);
  }, [data?.isLiked]);

  return (
    <div className="conf-card ">
      <div
        className="cc-img-container"
        onClick={
          () => {
            if (tag === 'Video Course') {
              openInNewTab(`/courses/${data?._id}`);
            } else if (tag === 'Audio Course') {
              openInNewTab(`audio-courses/${data?._id}`);
            } else if (tag === 'Podcast') {
              openInNewTab(`/podcast/${data?._id}`);
            } else {
              openInNewTab(`/search-conference/${data?._id}`);
            }
          }
          //   navigate(`/search-conference/${confId}`);
        }
      >
        {data?.banner[0]?.Location ? (
          <img src={data?.banner[0]?.Location} alt="conference banner" />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              padding: 40,
              backgroundColor: '#ecf0f2',
            }}
          >
            <i>
              <CameraIcon className="icon-size" />
            </i>
          </div>
        )}
      </div>
      <div className="cc-content-container">
        <div
          className="cc-headtrunc-wrap"
          onClick={() => {
            if (tag === 'Video Course') {
              openInNewTab(`/courses/${data?._id}`);
            } else if (tag === 'Audio Course')
              openInNewTab(`audio-courses/${data?._id}`);
            // openInNewTab(`courses/${data?._id}`);
            else if (tag === 'Podcast') {
              openInNewTab(`podcast/${data?._id}`);
            } else openInNewTab(`/search-conference/${data?._id}`);
          }}
        >
          <div className="coursecard-header mb-16">{data?.title}</div>
          <div className="card-description mb-8">
            <span className="caption-2-regular-gray3 mr-8  ">Hosted By:</span>
            <span className="caption-2-regular-gray3">
              {data?.hostedBy?.user
                ? `${data?.hostedBy?.user?.firstName} ${data?.hostedBy?.user?.lastName}`
                : data?.hostedBy?.organization?.name}
            </span>
          </div>
          {data?.credits?.length > 0 ? (
            <div className="cc-hovcard-credits mb-8" style={{ padding: 0 }}>
              <div className="caption-2-regular-gray3 ">
                {data?.credits[0].creditId?.name}{' '}
              </div>
              <div className="caption-2-regular-gray3 ">
                {data?.credits[0].quantity}{' '}
              </div>
            </div>
          ) : (
            <div>"Credits not added"</div>
          )}

          <div className="card-description">{data?.description}</div>
        </div>
        <div className="confcard-footer ">
          {data?.type === 'AUDIO' || data?.type === 'VIDEO' ? (
            <div
              className="flex-vc caption-2-bold-cblack "
              style={{
                width: '100%',
                height: '100%',
                fontSize: 16,
                gap: '8px',
                wordBreak: 'break-word',
              }}
            >
              {getPrice(
                data?.currency,
                data?.actualPrice,
                data?.price,
                data?.currencySymbol
              )}
            </div>
          ) : (
            <div
              className="flex-vc caption-2-bold-cblack "
              style={{
                width: '100%',
                height: '100%',
                fontSize: 16,
                gap: '8px',
                wordBreak: 'break-word',
              }}
            >
              {getPrice(
                data?.currency,
                data?.monthlyPodcastPrice?.actualPrice,
                data?.monthlyPodcastPrice?.price,
                data?.currencySymbol
              )}
            </div>
          )}

          <div style={{ cursor: 'pointer' }}>
            {isLiked ? (
              <i
                className="conference-card-buttons"
                onClick={() => unlike(data?._id, user?._id, setIsLiked)}
              >
                {/* <LikeRedIcon className="icon-sm" /> */}
                <LikeBlueIcon className="icon-size" />
              </i>
            ) : (
              <i
                className="conference-card-buttons"
                onClick={() =>
                  like(data?._id, user?._id, setIsLiked, data?.type)
                }
              >
                <LikeInactiveIcon className="icon-size" />
              </i>
            )}
          </div>
        </div>
      </div>
      {!hideHovCard && <CourseHoverCard conf={data} tag={tag} />}
    </div>
  );
}

CourseCard.propTypes = {
  data: PropTypes.object.isRequired,
  hideHovCard: PropTypes.bool,
};
