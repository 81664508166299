import './mediaIncludesCard.scss';
import PlusIcon from '../icons/PlusIcon';
import MediaDetailVideoIcon from '../icons/MediaDetailVideoIcon';
import MediaDetailMobileIcon from '../icons/MediaDetailMobileIcon';
import MediaDetailYearAccessIcon from '../icons/MediaDetailYearAccessIcon';
import MediaDetailDownlodableIcon from '../icons/MediaDetailsDownlodable';
import MediaDetailCreditIcon from '../icons/MediaDetailCreditIcon';

const MediaIncludesCard = ({ icon = null, text = '' }) => {
  return (
    <div className="mic-box">
      <i>
        {icon === 'videoIcon' && <MediaDetailVideoIcon className="icon-size" />}
        {icon === 'mobileIcon' && (
          <MediaDetailMobileIcon className="icon-size" />
        )}
        {icon === 'yearOfAccessIcon' && (
          <MediaDetailYearAccessIcon className="icon-size" />
        )}
        {icon === 'downladableResourse' && (
          <MediaDetailDownlodableIcon className="icon-size" />
        )}
        {icon === 'certificateIcon' && (
          <MediaDetailCreditIcon className="icon-size" />
        )}
      </i>
      <p className="avenir-16-500">{text}</p>
    </div>
  );
};

export default MediaIncludesCard;
