import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PracticeAddressForm from './PracticeAddressForm';
import Edit2Icon from '../icons/Edit2Icon';
import TranshcanIcon from '../icons/TrashcanIcon';

import api from '../../utility/api';

import { alertAction } from '../../redux/alert/alertAction';
import { loadUserProfileAction } from '../../redux/user-profile/userProfileAction';
import DialogueWithLoader from '../dialogue/DialogueWithLoader';

export default function PracticeAddress({ practice, indx }) {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialogue, setDeleteDialogue] = useState(false);
  const [practiceId, setPracticeId] = useState('');

  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    const newAddresses = userProfile?.practiceAddress?.filter(
      (address) => address._id !== practiceId
    );
    setIsLoading(true);
    const formData = {
      user: {
        practiceAddress: newAddresses,
      },
    };
    try {
      const response = await api.patch(`/users/${userProfile._id}`, formData);
      if (response) {
        dispatch(loadUserProfileAction(response.data.data.user));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  return (
    <div className="mb-40">
      <div className="flex-vc mb-13">
        <h4>{practice?.name || `Practice Name`}</h4>
        <span
          style={{ cursor: 'pointer' }}
          className="mr-8 ml-12"
          onClick={() => {
            setEditMode(!editMode);
          }}
        >
          <Edit2Icon />
        </span>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setDeleteDialogue(true);
            setPracticeId(practice._id);
          }}
        >
          <TranshcanIcon />
        </span>
      </div>

      {editMode ? (
        <PracticeAddressForm
          practice={practice}
          indx={indx}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      ) : (
        <div className="body-regular-gray3">
          <p>{practice?.addressLine1}</p>
          <p>{practice?.addressLine2}</p>
          <p>
            {practice?.city}, {practice?.state}
          </p>
          <p>
            {practice?.country} - {practice?.zipcode}
          </p>
        </div>
      )}
      {deleteDialogue && (
        <DialogueWithLoader
          msg="Are you sure you want to delete practice address?"
          title="Delete Practice addresss"
          closeDialogue={() => {
            setDeleteDialogue(false);
          }}
          previewLoading={isLoading}
          yesAction={() => handleDelete()}
        />
      )}
    </div>
  );
}

PracticeAddress.propTypes = {
  practice: PropTypes.object.isRequired,
  indx: PropTypes.number,
};
