import { Fragment, useRef } from 'react';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertAction, localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { useNavigate } from 'react-router-dom';
import {
  getFormattedDateInTz2,
  getValue,
  renderRowStyle,
  scrollToTop,
} from '../../utility/commonUtil';
import PageLimitSelect from '../pagination/PageLimitSelect';
import CustomPagination from '../pagination/CustomPagination';
import Select from 'react-select';
import { customSelectStyle3 } from '../reselect/selectUtil';

import '../conference/myConfs.styles.scss';

import DatePicker from 'react-datepicker';
import './purchaseHistory.scss';
import Dialogue from '../dialogue/Dialogue';

import ModalX from '../modal/ModalX';
import AttendeeBookingDetails from './AttendeeBookingDetails';
import PurchaseDetails from './PurchaseDetails';
import DownloadIcon from '../icons/DownloadIcon';
import PurchaseInfoIcon from '../icons/PurchaseInfoIcon';
import PurchasePrintIcon from '../icons/PurchasePrintIcon';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import CreditsIcon from '../icons/CreditsIcon';
import ConfemyLogo from '../../assets/2.png';
import CourseRecieptPrint from './CourseReceiptPrint';
import ConferenceReceiptPrint from './ConferenceReceiptPrint';

const options1 = [
  { label: 'All', value: 'all' },
  { label: 'Video Course', value: 'Video Course' },
  { label: 'Conference', value: 'Conference' },
];

const PurchaseHistoryTable = ({}) => {
  const [bookingToCancel, setBookingToCancel] = useState(null);

  const [openDialogue, setOpenDialogue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [bookings, setBookings] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});
  const [media, setMedia] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filter1, setFilter1] = useState('all');

  const [openModalX, setOpenModalX] = useState(false);
  const [openPurchaseModal, setPurchaseModal] = useState(false);
  const [openPodcastModal, setPodcastModal] = useState(false);
  const [showCancelButtons, setShowCancelButtons] = useState(false);
  const [coursePrint, setCoursePrint] = useState(false);

  // for pagination
  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };
  const componentRef = useRef();

  const generateAndSavePDF = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF

    const options = {
      margin: 1,
      filename: 'myDocument.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    await html2pdf().from(element).set(options).save();
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  //  on clicking a row cell edit the speaker
  async function onRowClick(booking, open) {
    try {
      let response = '';
      if (booking?.eventType === 'Conference') {
        setCoursePrint(false);

        let response = await api.get(
          `/conferences/bookings/${booking?._id}/${booking?.attendees[0]?._id}`
        );

        setBookingDetails(response.data.data.bookingDetails);
        setIsLoading(false);
        if (open) {
          setOpenModalX(true);
        }
      } else {
        setCoursePrint(true);
        response = await api.get(
          `/courses/videoCourse/purchaseHistory/${booking?._id}/users/${user?._id}`
        );

        setBookingDetails(response.data.data.videoCourse);

        setIsLoading(false);

        if (open) {
          if (
            booking?.eventType === 'Video Course' ||
            booking?.eventType === 'Audio Course'
          ) {
            setPurchaseModal(true);
          } else {
            setPodcastModal(true);
          }
        }
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
      setIsLoading(false);
      setOpenModalX(false);
    }
    // setBookingToShow(booking);
    // setShowBooking(true);
  }
  console.log({ coursePrint });
  const cancelTicket = async (guest) => {
    try {
      console.log({ guest });
      let formData = {
        atteendeeDetails: {
          transactionId: guest?.bookingDetails,
          attendeeId: guest?._id,
          userId: user?._id,
        },
      };

      const response = await api.post(`/attendees/refunds`, formData);
      if (response) {
        console.log({ response });
        getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        setBookingDetails(response.data.data.attendee);
        setShowCancelButtons(false);
      }
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
      setShowCancelButtons(false);
    }
  };

  const cancelPurchase = async (guest) => {
    try {
      let formData = {
        purchaseDetails: {
          purchaseId: guest?._id,
          userId: user?._id,
        },
      };
      const response = await api.post(
        `/attendees/videoCourses/refunds?pageLimit=${pageLimit}&currentPage=${activePage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`,
        formData
      );
      if (response) {
        setShowCancelButtons(false);
        setBookingDetails(response.data.data.purchase);
        // getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        //  setBookingDetails(response.data.data.booking);
        if (response.data.data?.page === 1) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setBookings(response.data.data.bookings);
        setTotalRecords(response.data.data.totalBookingCount);
      }
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
      setShowCancelButtons(false);
    }
  };

  const cancelPodcast = async (guest) => {
    try {
      let formData = {
        purchaseDetails: {
          purchaseId: guest?._id,
          userId: user?._id,
        },
      };
      const response = await api.post(
        `/attendees/videoCourses/refunds?pageLimit=${pageLimit}&currentPage=${activePage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`,
        formData
      );
      if (response) {
        setShowCancelButtons(false);
        setBookingDetails(response.data.data.purchase);
        // getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
        //  setBookingDetails(response.data.data.booking);
        if (response.data.data?.page === 1) {
          setCurrentPageNumber({ currentPage: response.data.data?.page });
          setCurrentPage(response.data.data?.page);
        }
        setBookings(response.data.data.bookings);
        setTotalRecords(response.data.data.totalBookingCount);
      }
    } catch (err) {
      dispatch(
        localAlertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
      setShowCancelButtons(false);
    }
  };

  const getAllBookings = async (
    currentPage,
    limit,
    filter1,
    startDate,
    endDate
  ) => {
    try {
      let response = await api.get(
        `/courses/videoCourse/users/${user?._id}/bookings?pageLimit=${limit}&currentPage=${currentPage}&eventType=${filter1}&startDate=${startDate}&endDate=${endDate}`
      );

      if (response.data.data?.page === 1) {
        setCurrentPageNumber({ currentPage: response.data.data?.page });
        setCurrentPage(response.data.data?.page);
      }
      setBookings(response.data.data.bookings);
      setTotalRecords(response.data.data.totalBookingCount);
    } catch (error) {
      dispatch(alertAction(error.response.data.message, 'danger'));
    }
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;
    getAllBookings(currentPage, pageLimit, filter1);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    getAllBookings(activePage, pageLimit, filter1, startDate, endDate);
    return () => setBookingDetails(null);
  }, [user?._id, filter1, startDate, endDate]);

  return (
    <div className="mb-24">
      <h2 className="color-primary mb-16">Purchase History</h2>
      <div className="credit-flex-vc-sb mb-16">
        <div className="myconfs-sort mb-16">
          <div>
            <div>
              <p className="caption-1-regular-gray3 ml-2 mb-8">Event Type</p>
              <Select
                value={getValue(options1, filter1, false)}
                options={options1}
                name="filter1"
                placeholder="Select event type."
                onChange={(option) => {
                  setFilter1(option.value);
                }}
                styles={customSelectStyle3}
              />
            </div>
          </div>
          <div>
            <div className="form-type-3">
              <p className="caption-1-regular-gray3 ml-2 mb-8">From Date</p>
              <DatePicker
                selected={startDate}
                onChange={(val) => setStartDate(val)}
                isClearable
                placeholderText="From"
              />
            </div>
          </div>
          <div>
            <div className="form-type-3">
              <p className="caption-1-regular-gray3 ml-2 mb-8">To Date</p>
              <DatePicker
                selected={endDate}
                onChange={(val) => setEndDate(val)}
                isClearable
                placeholderText="Until"
                minDate={startDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="table-container">
        <ul className="ph-gridtable grid-table-5-800">
          <li>Event Title</li>
          <li>Event Type</li>
          <li>Date</li>
          <li>Status</li>
          <li>
            <div className="text-align-center">Actions</div>
          </li>

          {bookings?.length > 0 &&
            bookings?.map((data, indx) => (
              <Fragment key={data._id}>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  onClick={() => onRowClick(data, true)}
                >
                  <p
                    className="avenir-heavy-18"
                    style={{ color: '#000', fontSize: 16 }}
                  >
                    {data?.title}
                  </p>
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {data.eventType}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {getFormattedDateInTz2(data?.bookingDate, data?.timezone)}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                  // onClick={() => onRowClick(data)}
                >
                  {data?.booked === 1 ? (
                    <span
                      style={{
                        color: '#4CB944',
                      }}
                    >
                      Payment complete{' '}
                    </span>
                  ) : (
                    <span style={{ color: '#bb2124' }}>Cancelled</span>
                  )}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={() => handleMouseLeave(data?._id)}
                  style={renderRowStyle(indx, data?._id, isHover, rowId)}
                >
                  <div
                    className="flex-vc"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        onRowClick(data, true, false);
                      }}
                    >
                      <PurchasePrintIcon />
                    </i>
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        onRowClick(data, false, true);
                        setTimeout(function () {
                          generateAndSavePDF();
                        }, 5000);
                      }}
                      className="mx-8"
                    >
                      <DownloadIcon />
                    </i>
                  </div>
                </li>
              </Fragment>
            ))}
        </ul>
      </div>
      {openModalX && !isLoading && (
        <ModalX
          onDismiss={() => setOpenModalX(false)}
          open={openModalX && !isLoading}
        >
          <div className="modalx-container-md mb-32 px-20">
            <AttendeeBookingDetails
              bookingDetails={bookingDetails}
              cancelTicket={cancelTicket}
              showCancelButtons={showCancelButtons}
              setShowCancelButtons={setShowCancelButtons}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )}
      {(openPurchaseModal || openPodcastModal) && !isLoading && (
        <ModalX
          onDismiss={() =>
            openPurchaseModal ? setPurchaseModal(false) : setPodcastModal(false)
          }
          open={openPurchaseModal && !isLoading}
        >
          <div className="modalx-container-md mb-32 px-20">
            <PurchaseDetails
              refValue={componentRef}
              generateAndSavePDF={generateAndSavePDF}
              bookingDetails={bookingDetails}
              cancelTicket={openPodcastModal ? cancelPurchase : cancelPodcast}
              showCancelButtons={showCancelButtons}
              setShowCancelButtons={setShowCancelButtons}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )}
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
      {openDialogue && (
        <Dialogue
          msg="Are you sure you want to cancel the purchase?"
          title="Confirm cancel purchase !!"
          closeDialogue={() => {
            setOpenDialogue(false);
            setBookingToCancel(null);
          }}
          yesAction={() => {}}
        />
      )}

      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="print-margins">
          {coursePrint ? (
            <CourseRecieptPrint bookingDetails={bookingDetails} />
          ) : (
            <ConferenceReceiptPrint bookingDetails={bookingDetails} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistoryTable;
