import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeroSketch from '../icons/HeroSketch';

import './hero.styles.scss';

function Hero() {
  const navigate = useNavigate();
  return (
    <section className=" hero-section bg-background">
      <div className="hero-container">
        <div className="hero-text">
          <p className="landing-page-title">Discover the best</p>
          <p className="landing-huge-title">
            Medical & Dental Conferences, Video Courses & Podcasts
          </p>
          <button
            onClick={() => {
              // loadSearchResults();
              navigate('/search-conference');
            }}
            className="button button-green hero-btn"
          >
            Explore
          </button>
        </div>
        <div className="">
          <HeroSketch className="hero-sketch" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
