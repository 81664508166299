import {
  CREATE_VIDEO_COURSE,
  REMOVE_NEWVC_STATE,
  LOAD_INCOMPLETE_VCS,
  LOAD_INCOMPLETE_VC,
  LOAD_ALL_MY_VCS,
  VC_SEARCH_INITIATED,
  GET_SINGLE_VIDEO_COURSE,
  LOAD_USER_VIDEO_COURSE_CREDIT,
  ADD_FILE_DURATION,
  //   VIDEO_COURSE_ERROR,
  //   VC_SEARCH_DONE,
  //   GET_VIDEO_COURSE,
} from './videoCourseTypes';

const initialState = {
  isLoading: true,
  newVideoCourse: null,
  incompleteVideoCourses: null,
  myVideoCourses: null,
  userCreditVideoCourses: [],
  videoCourse: null,
  duration: {},
  error: false,
  search: {
    isLoading: false,
    error: false,
    videoCourses: [],
    allTotal: null,
    atVenueTotal: null,
    onlineTotal: null,
    videoTotal: null,
    audioTotal: null,

    // liveStreamVideoCourses: [],
  },
};

function videoCourseReducer(state = initialState, action) {
  const { type, payload } = action;
  let searchObj = state.search;

  switch (type) {
    case CREATE_VIDEO_COURSE:
    case LOAD_INCOMPLETE_VC:
      return {
        ...state,
        isLoading: false,
        newVideoCourse: payload,
        error: false,
      };

    case GET_SINGLE_VIDEO_COURSE:
      return {
        ...state,
        isLoading: false,
        videoCourse: payload,
        error: false,
      };
    case ADD_FILE_DURATION:
      return {
        ...state,
        isLoading: false,
        duration: payload,
        error: false,
      };

    case LOAD_USER_VIDEO_COURSE_CREDIT:
      return {
        ...state,
        isLoading: false,
        userCreditVideoCourses: payload,
        error: false,
      };

    case REMOVE_NEWVC_STATE:
      return {
        ...state,
        isLoading: true,
        newVideoCourse: {},
        error: false,
      };

    case LOAD_INCOMPLETE_VCS:
      return {
        ...state,
        incompleteVideoCourses: payload,
      };

    case LOAD_ALL_MY_VCS:
      return {
        ...state,
        myVideoCourses: payload,
      };

    case VC_SEARCH_INITIATED:
      return {
        ...state,
        search: {
          ...searchObj,
          isLoading: true,
        },
      };

    case LOAD_ALL_MY_VCS:
      return {
        ...state,
        search: {
          isLoading: false,
          error: false,
          videoCourses: payload?.videoCourses,
          allTotal: payload?.totalConfCount,
          atVenueTotal: payload?.venueBasedConfCount,
          onlineTotal: payload?.onlineConfCount,
          videoTotal: payload?.videoConfCount,
          audioTotal: payload?.audioConfCount,
        },
      };
    default:
      return state;
  }
}

export default videoCourseReducer;
