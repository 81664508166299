import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  initMessageListener,
} from 'redux-state-sync';
import rootReducer from './rootReducer'; // Your root reducer file
import logger from 'redux-logger';

// Redux-Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ["profile", "conference", "userLocation"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux-State-Sync configuration
const stateSyncConfig = {
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
};
const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

const middlewares = [thunk, stateSyncMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

// Set up the message listener for state synchronization
initMessageListener(store);

// Initialize state with previous tab's state (optional)
initStateWithPrevTab(store);

const persistor = persistStore(store);

export { store, persistor };
