import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import MediaTag from '../../components/tags/MediaTag.js';
import MediaIncludes from '../../components/courses-details/MediaIncludes.js';
import api from '../../utility/api.js';
import { alertAction } from '../../redux/alert/alertAction.js';
import PodcastDetailsCard from '../../components/cards/PodcastDetailsCard.js';
import {
  getFormattedDateInTz2,
  getPodcastStatus,
  getPrice,
  likeMedia,
  unlikeConference,
} from '../../utility/commonUtil.js';
import ModalX from '../../components/modal/ModalX.js';
import ReportAbuseForm from '../../components/report-abuse/ReportAbuse.js';
import Loader from '../../components/loader/Loader.js';
import '../course-details-page/videoCourseDetailsPage.scss';
import { loadSinglePodAction } from '../../redux/podcast/podcastAction.js';
import PodcastContent from '../../components/podcast/PodcastContent.js';
import PodcastDetailsTop from '../../components/podcast/PodcastDetailsTop.js';
import LikeIcon from '../../components/icons/LikeRedIcon.js';
import LikeInactiveIcon from '../../components/icons/LikeInactiveIcon.js';
import PodcastResTabs from '../../components/tabs/PodcastResTabs.js';
import confemyLogo from '../../assets/2.png';

export default function PodcastPreviewPage() {
  const [isLoading, setIsloading] = useState(true);
  const [media, setMedia] = useState({});
  const [moreMediaByHost, setMoreMediaByHost] = useState([]);
  const [sectionCount, setSectionCount] = useState(0);
  const [episodeCount, setEpisodeCount] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [video, setVideo] = useState({});
  const [previewEpisode, setPreviewEpisode] = useState({});
  const [showReportAbuseForm, setShowReportAbuseForm] = useState(false);
  const [episodeId, setEpisodeId] = useState('');
  const [openDeleteDialogue, setDeleteDialogue] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, user } = auth;
  const mediaId = useParams().mediaId;
  const dispatch = useDispatch();

  const modalHandler = () => {
    setVideo({});
    setShowPreviewModal(false);
  };

  const playPreview = () => {
    setVideo(previewEpisode);
    setShowPreviewModal(true);
  };

  const navigate = useNavigate();

  const like = (mediaId, userId, setIsLiked, type) => {
    if (!isLiked && isAuthenticated && user) {
      likeMedia(mediaId, userId, setIsLiked, type);
    } else {
      dispatch(alertAction('Please login to like a media.', 'info'));
    }
  };

  const unlike = (confId, userId, setIsLiked) => {
    if (isLiked && isAuthenticated && user) {
      unlikeConference(confId, userId, setIsLiked);
    } else {
      dispatch(alertAction('Please login to unlike a conference.', 'info'));
    }
  };

  const getMediaDetails = async () => {
    try {
      // const response = await api.get(`/media/${mediaId}`);
      const response = await api.get(`/media/podcasts/${mediaId}`);

      setMedia(response.data.data.media);
      dispatch(loadSinglePodAction(response.data.data.media));
      setPreviewEpisode(response.data.data.previewEpisode);
      setMoreMediaByHost(response.data.data.moreCourses);
      setSectionCount(response.data.data.sectionCount);
      setEpisodeCount(response.data.data.episodeCount);
      setIsloading(false);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const deleteEpisode = async (mediaId, episodeId) => {
    try {
      // const response = await api.get(`/media/${mediaId}`);
      const response = await api.delete(
        `/podcast/${mediaId}/preview/episodes/${episodeId}`
      );

      setMedia(response.data.data.media);
      dispatch(loadSinglePodAction(response.data.data.media));
      setPreviewEpisode(response.data.data.previewEpisode);
      setMoreMediaByHost(response.data.data.moreCourses);
      setSectionCount(response.data.data.sectionCount);
      setEpisodeCount(response.data.data.episodeCount);
      setEpisodeId(episodeId);
      setDeleteDialogue(false);
      setIsloading(false);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    getMediaDetails();
  }, []);

  return (
    <div className="container pt-64">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PodcastDetailsTop
            podcastId={media?._id}
            status={getPodcastStatus(media)}
          />
          <div className="md-top-container ">
            <div className="md-grid">
              <div>
                <div className="mb-12">
                  <MediaTag tag={media?.mediaType} />
                </div>
                <h2
                  className="mb-16"
                  style={{
                    color: '#fff',
                  }}
                >
                  {media?.title}
                </h2>
                <div className="mb-4">
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    Created by {'  '}
                  </span>
                  <span className="avenir-16-500" style={{ color: '#fff' }}>
                    {media?.hostedBy?.user
                      ? `${media?.hostedBy?.user?.firstName} ${media?.hostedBy?.user?.lastName}`
                      : media?.hostedBy?.organization?.name}
                  </span>
                </div>
                <div className="avenir-16-500 mb-16" style={{ color: '#fff' }}>
                  <span>Last Updated </span>
                  <span>
                    {getFormattedDateInTz2(media?.updatedAt, 'Asia/Kolkata')}
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: 15 }}>{media?.description}</p>
                </div>
                <div className="media-res-details-con">
                  {media?.monthlyPodcastPrice?.price > 0 ||
                  media?.yearlyPodcastPrice?.price > 0 ? (
                    <>
                      {' '}
                      <div className="mt-16">
                        <PodcastResTabs
                          tabs={[
                            {
                              label: `${media?.currency} ${media?.monthlyPodcastPrice?.actualPrice} monthly`,
                              path: `/podcast/${media?._id}/monthly`,
                            },
                            {
                              label: `${media?.currency} ${media?.yearlyPodcastPrice?.actualPrice} yearly`,
                              path: `/podcast/${media?._id}/yearly`,
                            },
                          ]}
                          showRadioButtons={false}
                        />
                      </div>
                      <div>
                        <Outlet />
                      </div>
                    </>
                  ) : (
                    <div
                      className="flex-vc caption-2-bold-cblack "
                      style={{
                        fontSize: 16,
                        gap: '8px',
                        wordBreak: 'break-word',
                        marginBottom: '100px',
                      }}
                    >
                      {getPrice(
                        media?.currency,
                        media?.monthlyPodcastPrice?.actualPrice,
                        media?.monthlyPodcastPrice?.price,
                        media?.monthlyPodcastPrice?.currencySymbol
                      )}
                    </div>
                  )}
                  <div className="flex-vc">
                    <button
                      className="small-button small-button-green mr-10"
                      style={{ flexGrow: 1, height: 54 }}
                      onClick={() => {
                        if (media?.tag === 'Video Course' || 'Audio Course') {
                          navigate(`/book-course/${media?._id}`);
                        } else if (media?.tag === 'Podcast')
                          navigate(`/podcast/booking-price/${media?._id}`);
                        else {
                          navigate(`/book-conference/${media?._id}`);
                        }
                      }}
                    >
                      Book
                    </button>

                    <span
                      className="primary-color-box "
                      style={{ cursor: 'pointer' }}
                    >
                      {isLiked ? (
                        <i
                          className="conference-card-buttons"
                          onClick={() =>
                            unlike(
                              media?._id,
                              user?._id,
                              setIsLiked,
                              media?.type
                            )
                          }
                        >
                          {/* <LikeRedIcon className="icon-sm" /> */}
                          <LikeIcon className="icon-lg" />
                        </i>
                      ) : (
                        <i
                          className="conference-card-buttons"
                          onClick={() =>
                            like(media?._id, user?._id, setIsLiked, media?.type)
                          }
                        >
                          <LikeInactiveIcon className="icon-lg" />
                        </i>
                      )}
                    </span>
                    {/* <LikeInactiveIcon className="icon-size" /> */}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: 320,
                  position: 'relative',
                }}
              >
                <PodcastDetailsCard
                  podcast={media}
                  playPreview={playPreview}
                  preview={true}
                />
              </div>
            </div>
          </div>
          <div className="md-bottom-container">
            <div className="md-grid">
              <div>
                <div className="mb-24">
                  <MediaIncludes courseIncludes={media?.courseIncludes} />
                </div>
                <div className="mb-24">
                  <PodcastContent
                    data={media}
                    sectionCount={sectionCount}
                    episodeCount={episodeCount}
                    preview={true}
                    deleteEpisode={deleteEpisode}
                    episodeId={episodeId}
                    setEpisodeId={setEpisodeId}
                    openDeleteDialogue={openDeleteDialogue}
                    setDeleteDialogue={setDeleteDialogue}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 320,
                }}
              >
                {/* need this empty */}
              </div>
            </div>
          </div>
          {showPreviewModal && previewEpisode && (
            <div className=" media-player-preview">
              <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
                <div>
                  <ReactPlayer
                    controls={true}
                    playing={true}
                    url={video?.source?.Location}
                    width="100%"
                    height="100%"
                    config={{
                      file: { attributes: { controlsList: 'nodownload' } },
                    }}
                    loop={true}
                  />
                  {/* <img
                  src={confemyLogo}
                  alt="Logo"
                  className="react-player-video-logo"
                /> */}
                </div>
              </ModalX>
            </div>
          )}

          {showReportAbuseForm && (
            <ModalX
              onDismiss={() => setShowReportAbuseForm(false)}
              open={showReportAbuseForm}
            >
              <div className="modalx-container-sm mb-40">
                <ReportAbuseForm
                  setShowReportAbuseForm={setShowReportAbuseForm}
                  data={media}
                />
              </div>
            </ModalX>
          )}
        </>
      )}
    </div>
  );
}
