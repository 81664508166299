import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceIcon from "../../components/icons/PriceIcon";
import SuccessTickIcon from "../../components/icons/SuccessTickIcon";
import WorldIcon from "../../components/icons/WorldIcon";
import { alertAction } from "../../redux/alert/alertAction";
import api from "../../utility/api";
import "../organizer-dashboard-page/organizerDashboard.styles.scss";
import CreditsIcon from "../../components/icons/CreditsIcon";
import CountCard from "../../components/attendee-dashboard/CountCard";
import ExploreEvents from "../../components/attendee-dashboard/ExploreEvents";
import UpcomingConfs from "../../components/attendee-dashboard/UpcomingConfs";
import RecentlyWishlisted from "../../components/attendee-dashboard/RecentlyWishlisted";
import OngoingLearning from "../../components/attendee-dashboard/OngoingLearning";
import MyEvents from "../../components/attendee-dashboard/MyEvents";
import DashbardCreditGoal from "../../components/attendee-dashboard/DashboardCreditGoal";

export default function AttendeeDashboardPage() {
  const [dashboardCounts, setDashboardCounts] = useState(null);
  const [upcomingConfs, setUpcomingConfs] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [ongoingLearning, setOngoingLearning] = useState([]);
  const [goals, setGoals] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const getDashboardCounts = async (userId) => {
    try {
      const response = await api.get(
        `/attendees/users/${userId}/dashboard/counts`
      );

      setDashboardCounts(response?.data?.data);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "error"
        )
      );
    }
  };

  const getUpcomingConfs = async (userId) => {
    try {
      const response = await api.get(`/attendees/users/${userId}/futureConfs`);
      setUpcomingConfs(response?.data?.data?.conferences);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "error"
        )
      );
    }
  };

  const getWishlist = async (userId) => {
    try {
      const response = await api.get(
        `/attendees/users/${userId}/dashboard/wishlist`
      );

      setWishList(response?.data?.data?.wishListedData);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "error"
        )
      );
    }
  };

  const getOngoingLearning = async (userId) => {
    try {
      const response = await api.get(`/courses/users/${userId}/latestViewed`);
      setOngoingLearning(response?.data?.data?.latestViewedData);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "error"
        )
      );
    }
  };

  const getGoals = async (userId) => {
    try {
      const response = await api.get(
        `attendees/goals/duration?userId=${userId}`
      );

      setGoals(response.data.data.creditGoal);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    getUpcomingConfs(user?._id);
    getDashboardCounts(user?._id);
    getWishlist(user?._id);
    getOngoingLearning(user?._id);
    getGoals(user?._id);
  }, [user?._id]);

  return (
    <>
      <div className="ad-content-wrapper mb-20">
        <ExploreEvents />
        <DashbardCreditGoal goals={goals} />
      </div>
      <div className="ad-namecard-grid mb-40">
        <div>
          <CountCard
            header="Conferences"
            num={dashboardCounts?.confBookings}
            icon={<PriceIcon className="icon-lg" color="warning" />}
            path="/attendee-dashboard/booked-confs"
            subHeader="Explore more"
          />
        </div>
        <div>
          {" "}
          <CountCard
            header="Video Course"
            num={dashboardCounts?.videoCourseBookings}
            icon={<CreditsIcon className="icon-xl" color="primary" />}
            path="/attendee-dashboard/video-courses"
            subHeader="Explore more"
          />
        </div>
        <div>
          <CountCard
            header="Audio Course"
            num={dashboardCounts?.audioCourseBookings}
            icon={<SuccessTickIcon className="icon-lg" color="error" />}
            path="/attendee-dashboard/my-audio-courses"
            subHeader="Explore more"
          />
        </div>
        <div>
          <CountCard
            header="Podcast"
            num={dashboardCounts?.podcastBookings}
            icon={<WorldIcon className="icon-lg" color="secondary" />}
            path="/attendee-dashboard/my-podcasts"
            subHeader="See details"
          />
        </div>
        <div>
          <CountCard
            header="Earned Credits"
            num={dashboardCounts?.earndedCredits}
            icon={<CreditsIcon className="icon-xl" color="primary" />}
            path="/attendee-dashboard/my-credits"
            subHeader="Credit details"
          />
        </div>
      </div>
      <div className="ad-content-wrapper mb-40">
        <UpcomingConfs upcomingConfs={upcomingConfs} />
        <RecentlyWishlisted wishList={wishList} />
        <OngoingLearning ongoingLearning={ongoingLearning} />
        <MyEvents myEvents={dashboardCounts} />
      </div>
    </>
  );
}
