import { useFormik } from "formik";
import { useEffect, useState, Fragment } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as yup from "yup";
import { localAlertAction } from "../../../redux/alert/alertAction";
import api from "../../../utility/api";
import { getValue } from "../../../utility/commonUtil";
import CloseIcon from "../../icons/CloseIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import SearchIcon from "../../icons/SearchIcon";
import ThreeDotsVIcon from "../../icons/ThreeDotsVIcon";
// import Modal from '../../modal/Modal';
import { customSelectStyle3 } from "../../reselect/selectUtil";
// import AddDocuments from '../../upload-document/AddDocuments';
import "../../bookings-for-organizer/orgBookingTable.scss";
import CustomPagination from "../../pagination/CustomPagination";
import { renderRowStyle } from "../../../utility/commonUtil";
import UploadArrowIcon from "../../icons/UploadArrowIcon";
// import ModalX from '../../modal/ModalX';
// import OrgBookingDetails from './OrgBookingDetails';
import { alertAction } from "../../../redux/alert/alertAction";
import PageLimitSelect from "../../pagination/PageLimitSelect";
import Modal from "../../modal/Modal";
import AddDocuments from "../../upload-document/AddDocuments";
import ModalX from "../../modal/ModalX";
import VideoCourseRefundDetails from "../video-course-refund/VideoCourseRefundDetails";

const options = [
  { label: "All", value: "all" },
  {
    label: "Confirmed",
    value: 1,
  },
  {
    label: "Cancelled",
    value: 4,
  },
];

const refundObj = {
  1: "Refunded",
  2: "Initiated",
  5: "Rejected",
};

const validationSchema = yup.object().shape({
  fileName: yup.string().trim().required("Required").min(1).max(25),
  file: yup.array().min(1).required("Required"),
  type: yup.string().required("Required"),
  creditId: yup.string().when("type", {
    is: "creditCertificate",
    then: yup.string().required("Required. Conference is not a credited."),
  }),
  creditCustomQuantity: yup
    .number()
    .when(
      "creditQuantity",
      (creditQuantity, schema) =>
        creditQuantity &&
        schema
          .min(1, "Quantity must me greater than 0")
          .max(
            creditQuantity,
            `Quantity should less than or equal to ${creditQuantity}`
          )
          .required("Required")
    ),
});

export default function BdfoVideoCourseBooking({
  attendees,
  getBookings,
  videoCourse,
}) {
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [filter, setFilter] = useState("all");
  const [splicedList, setSplicedList] = useState([]);
  const [file, setFile] = useState({});
  const [progress, setProgress] = useState(false);
  const [attendee, setAttendee] = useState("");
  const [showModal, setShowModal] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [actionMenu, setActionMenu] = useState({ open: false, id: null });
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);

  // for pagination
  const [activePage, setActivePage] = useState({
    currentPage: 1,
  });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell
  async function onRowClick(id) {
    try {
      setDetailsLoading(true);
      const response = await api.get(
        `/attendees/videoCourse/${id}/purchaseDetails`
      );
      setBookingDetails(response.data.data?.purchaseDetails);

      setDetailsLoading(false);
    } catch (err) {
      setShowDetails(false);
      setDetailsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  }

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  let creditOptions = videoCourse?.credits?.map((credit) => {
    return {
      label: credit?.creditId?.name,
      quantity: credit?.quantity,
      value: credit?.creditId?._id,
    };
  });

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const viewCertificate = (certificate) => {
    window.open(certificate.location);
  };

  //code for frotnend pagination
  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = searchList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = searchList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  function closeActionMenu() {
    setActionMenu({ open: false, id: null });
  }

  const initialValues = {
    fileName: "",
    file: file,
    type:
      videoCourse && videoCourse?.isAccredited
        ? "creditCertificate"
        : "otherCertificate",
    creditOptions: creditOptions,
    creditQuantity: creditOptions?.[0]?.quantity,
    creditCustomQuantity: creditOptions?.[0]?.quantity,
    creditId: creditOptions?.[0]?.value,
  };

  const onSubmit = async (values) => {
    let formData = {
      purchaseDetails: {
        fileName: values?.fileName,
        creditId: values?.creditId,
        creditQuantity: values?.creditCustomQuantity,
        type: values?.type,
        data: [],
        userId: user?._id,
      },
    };

    setProgress(true);

    if (formik.values.file?.length > 0) {
      const fileDataObj = new FormData();
      formik.values.file.forEach((file) => fileDataObj.append("file", file));

      if (fileDataObj.has("file")) {
        try {
          const fileResponse = await api.post("/fileUploads", fileDataObj);

          if (fileResponse) {
            formData.purchaseDetails.data = fileResponse.data.data;
          }
        } catch (err) {
          setProgress(false);
          dispatch(
            localAlertAction(
              err?.response?.data?.message || "Something went wrong",
              "danger"
            )
          );
          return;
        }
      }
    }

    try {
      const response = await api.post(
        `/organizers/attendees/${attendee?._id}/videoCourse/uploadCertificate?mediaId=${attendee.media}`,
        formData
      );

      getBookings(attendee?.media);
      setProgress(false);
      formik.resetForm({});

      closeModal();
    } catch (error) {
      setProgress(false);
      dispatch(localAlertAction(error.response.data.message, "danger"));
    }
  };
  const closeBookingDetailsModal = () => {
    setShowDetails(false);
    getBookings(videoCourse?._id);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const closeModal = () => {
    setShowModal(false);
  };

  function gridtableMenu(attendee) {
    return (
      <>
        <div className="gridtable-row-menu">
          <div onClick={() => closeActionMenu()} className="flex-vc-sb">
            <span>Close</span>
            <i>
              <CloseIcon className="icon-xs" fill="#757575" />
            </i>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              closeActionMenu();
              onRowClick(attendee?._id);
            }}
          >
            <p>Open</p>
          </div>
          {attendee?.organiserAmount > 0 && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                closeActionMenu();
                onRowClick(attendee?._id);
                setShowDetails(true);
              }}
            >
              Refund
            </div>
          )}
          <div
            onClick={() => {
              onRowClick(attendee?._id);
              closeActionMenu();
            }}
          >
            <Dropzone
              onDrop={(acceptedFiles) => {
                //  setFile(acceptedFiles);
                formik.setFieldValue("file", acceptedFiles);
                setAttendee(attendee);
                setShowModal(true);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div className="flex-vc-sb">
                    <span>Certificate</span>
                    <i className="position-relative">
                      <UploadArrowIcon className="icon-xs" />
                    </i>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    let filteredAr = [];
    if (filter === "all") {
      filteredAr = attendees;
    }
    if (filter === 1) {
      filteredAr = attendees?.filter((item) => item.mediaBooked === 1);
    }

    if (filter === 4) {
      filteredAr = attendees?.filter((item) => item.mediaBooked === 4);
    }
    let searchData = filteredAr?.filter((item) => {
      if (
        item.user.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.serialNumber.includes(searchText.toLowerCase())
      ) {
        return item;
      }
    });

    setSearchList(searchData);
    setTotalRecords(searchData?.length);
  }, [attendees, filter, searchText]);

  // useEffect(() => {
  //   setFilteredList(attendees);
  //   // setSearchList(attendees);
  //   // setSplicedList(attendees);
  // }, [attendees]);

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [searchList]);

  return (
    <div>
      <div>
        <h4 className="color-primary mb-24">Video Course Bookings</h4>
      </div>
      <div className="bdfo-booking-table-sort">
        <div className="form-type-3">
          <div style={{ position: "relative" }}>
            <input
              type="text"
              id="myConfsSearchText"
              placeholder="Search Name or Booking No"
              name="searchText"
              value={searchText}
              onChange={handleSearchText}
              autoComplete="off"
            />
            <i
              className={
                searchText?.length > 0
                  ? "display-none"
                  : "conf-search-input-icon"
              }
            >
              <SearchIcon width="2.4rem" height="2.4rem" />
            </i>
          </div>
        </div>
        <Select
          // key={getValue(options, props.value)}
          value={getValue(options, filter, false)}
          onChange={(option) => {
            setFilter(option.value);
            // handlFilter(option.value);
          }}
          options={options}
          placeholder="Filter"
          name="filter"
          styles={customSelectStyle3}
        />
      </div>
      <div className="grid-table-wrap position-relative">
        <ul className="grid-table-8-1000 ">
          <li>Name</li>
          <li>Price</li>
          <li>Booking No</li>
          <li>Booking Date</li>
          <li>Status</li>
          <li>Refund Status</li>
          <li>Credits</li>
          <li>Action</li>
          {splicedList?.map((attendee, indx) => (
            <Fragment key={attendee?.bookingNumber}>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                {attendee?.user?.name}
              </li>

              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                <p>
                  {videoCourse?.currencySymbol && (
                    <span className="mr-4">{videoCourse?.currencySymbol}</span>
                  )}
                  <span>{attendee?.organiserAmount}</span>
                </p>
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                {attendee?.serialNumber}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                {new Date(attendee?.bookingDate).toDateString()}
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
                // style={{
                //   color: `${
                //     attendee?.ticketBooked == 1 ? "#08415c" : "#d8000c"
                //   }`,
                // }}
              >
                {
                  <div
                    style={{
                      color: `${attendee?.mediaBooked == 4 ? "#4cb944" : ""}`,
                    }}
                  >
                    <p>
                      {
                        options.find((e) => e.value === attendee.mediaBooked)
                          ?.label
                      }
                    </p>

                    {attendee?.cancelledBy?.userType && (
                      <p>
                        <span>by</span>{" "}
                        <span>
                          {attendee?.cancelledBy?.userType
                            .toString()
                            .toLowerCase()}
                        </span>
                      </p>
                    )}
                  </div>
                }
              </li>
              <li
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                <p>
                  {refundObj[attendee?.refundStatus]}
                  {/* {attendee?.refunded
                    ? "Refunded"
                    : attendee?.refundStatus === 5
                    ? "Refund rejected"
                    : ""} */}
                </p>
                {attendee?.refunded && attendee?.refundAmount && (
                  <p>
                    {videoCourse?.currencySymbol && (
                      <span className="mr-4">
                        {videoCourse?.currencySymbol}
                      </span>
                    )}
                    <span>{attendee?.refundAmount}</span>
                  </p>
                )}
              </li>
              <li
                //  style={{ cursor: "pointer" }}
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                onClick={() => onRowClick(attendee?._id)}
              >
                {attendee.approvedCredits?.length > 0 &&
                  attendee.approvedCredits.map((credit) => {
                    return (
                      <div
                        key={credit?._id}
                        className="flex-vc-sb  caption-1-regular-gray2"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="flex-vc"
                          onClick={(e) => {
                            e.stopPropagation();
                            viewCertificate(credit);
                          }}
                        >
                          <i className="position-relative mr-4">
                            <DocumentIcon className="icon-sm " />
                          </i>
                          <span className="hover-bg p-4">
                            {credit?.certificateName}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                {attendee.otherCertificateUploaded && (
                  <div className="flex-vc-sb mt-8 caption-1-regular-gray2">
                    <div
                      style={{ cursor: "pointer" }}
                      className="flex-vc"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewCertificate(attendee?.otherCertificate);
                      }}
                    >
                      <i className="position-relative mr-4">
                        <DocumentIcon className="icon-sm " />
                      </i>
                      <span className="hover-bg p-4">
                        {attendee?.otherCertificate?.certificateName}
                      </span>
                    </div>
                  </div>
                )}
              </li>
              <li
                className="position-relative"
                onMouseEnter={() => handleMouseEnter(attendee?._id)}
                onMouseLeave={() => handleMouseLeave(attendee?._id)}
                style={renderRowStyle(indx, attendee?._id, isHover, rowId)}
                //  onClick={() => onRowClick(data)}
              >
                {actionMenu.id == attendee?._id && actionMenu.open && (
                  <>{gridtableMenu(attendee)}</>
                )}
                <i
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActionMenu({ open: true, id: attendee?._id });
                  }}
                >
                  <ThreeDotsVIcon className="icon-size" />
                </i>
              </li>
            </Fragment>
          ))}
        </ul>
        {detailsLoading && !showDetails && <div className="ab-overlay"></div>}
      </div>
      {showDetails && !detailsLoading && (
        <ModalX onDismiss={closeBookingDetailsModal} open={showDetails}>
          <div className="modalx-container-md pb-24">
            <VideoCourseRefundDetails
              currencySymbol={videoCourse?.currencySymbol}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </div>
        </ModalX>
      )}
      {showModal && (
        <Modal onDismiss={closeModal}>
          <div className="refun-preview-wrap">
            <div className="modalx-header">
              <i onClick={closeModal} style={{ cursor: "pointer" }}>
                <CloseIcon className="icon-size" fill="#c4c4c4" />
              </i>
            </div>
            <AddDocuments
              isProgress={progress}
              formik={formik}
              fileData={file}
              attendeeId={attendee?._id}
              bookingDetails={bookingDetails}
            />
          </div>
        </Modal>
      )}
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
}
