import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../icons/SearchIcon";
import api from "../../utility/api";
import { customSelectStyle3 } from "../reselect/selectUtil";
import Select from "react-select";
import { getValue } from "../../utility/commonUtil";
import { useNavigate } from "react-router-dom";
import { alertAction } from "../../redux/alert/alertAction";
import { loadMyOrganizationsSelectListAction } from "../../redux/organization/myOrganizationsAction";
import MyConfsCard from "../conference/MyConfsCard";
import NextIcon from "../icons/NextIcon";
import { renderRowStyle } from "../../utility/commonUtil";

import "./creditRequests.styles.scss";
import CustomPagination from "../pagination/CustomPagination";
import PageLimitSelect from "../pagination/PageLimitSelect";

export default function CreditRequests() {
  const [filterText2, setFilterText2] = useState("all");
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [creditList, setCreditList] = useState();
  const [conferences, setConferences] = useState([]);
  const [actionMenu, setActionMenu] = useState({ open: false, id: null });

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const user = useSelector((state) => state.auth.user);
  const organizationsList = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options2 = [
    { value: "all", label: "All" },
    { value: "self", label: "User" },
    ...organizationsList,
  ];

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  //  on clicking a row cell
  function onRowClick(data) {
    navigate(`/attendee-credits/${data?.conference?._id}`);
  }

  function closeActionMenu() {
    setActionMenu({ open: false, id: null });
  }

  const onInputChange = (e) => setSearchText(e.target.value);

  const searchFilter = (data, value) => {
    let listToSearch = [];

    if (filterText2 === "all") {
      listToSearch = data;
    } else if (filterText2 === "self") {
      listToSearch = data?.filter((item) => item?.conference?.host === "user");
    } else {
      listToSearch = data?.filter(
        (element) =>
          element?.conference?.host === "organization" &&
          element?.conference?.hostedBy?.organization.toString() ===
            filterText2.toString()
      );
    }
    let filteredConfs = listToSearch?.filter((item) => {
      if (
        item.conference.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
      ) {
        return item;
      }
    });
    setTotalRecords(filteredConfs?.length);
    setConferences(filteredConfs);
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const confdata = conferences.slice(0, pageLimit);
      setCreditList(confdata);
    } else {
      const confdata = conferences.slice(
        indexOfFirstElement,
        indexOflastElement
      );
      setCreditList(confdata);
    }
  };

  const getOrganizerCredits = async (userId) => {
    try {
      const response = await api.get(`/organizers/${userId}/creditRequests`);

      if (response) {
        setFilteredList(response.data.data.conferences);
        setConferences(response.data.data.conferences);
        setCreditList(response.data.data.conferences);
        // dispatch(loadAllOrganizerCreditsAction(response.data.data.conferences));
      }
    } catch (error) {
      dispatch(alertAction(error.response.data.message, "error"));
    }
  };
  const loadMyOrgnizations = async (id) => {
    try {
      const response = await api.get(
        `organizations/users/${id}?orgForConference=true`
      );

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user._id);
    getOrganizerCredits(user._id);
  }, [user._id]);

  useEffect(() => {
    searchFilter(filteredList, searchText);
  }, [filteredList, searchText, filterText2]);

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [conferences]);

  return (
    <>
      <h1 className="mb-24">Credit Requests</h1>
      <div className="two-filters-wrap mb-24">
        <div className="form-type-3 ">
          <div className="position-relative">
            <input
              type="text"
              id="searchtickets"
              placeholder="Search Conference"
              name="searchText"
              value={searchText}
              onChange={onInputChange}
            />
            <i
              className={
                searchText?.length > 0
                  ? "display-none"
                  : "conf-search-input-icon"
              }
            >
              <SearchIcon width="2.4rem" height="2.4rem" />
            </i>
          </div>
        </div>
        <div>
          <Select
            value={getValue(options2, filterText2, false)}
            name="filterText2"
            options={options2}
            onChange={(value) => setFilterText2(value.value)}
            styles={customSelectStyle3}
          />
        </div>
      </div>

      <div className="grid-table-wrap">
        <ul className="grid-table-5-900 cr-gridtable">
          <li>Conference</li>
          <li>Pending</li>
          <li>Approved</li>
          <li>Requested</li>
          <li>Action</li>

          {creditList?.length > 0 &&
            creditList?.map((data, indx) => {
              return (
                <Fragment
                  key={data?._id}
                  // style={{
                  //   ...(actionMenu.id == data?._id && actionMenu.open
                  //     ? {
                  //         backgroundColor: "#ffffff",
                  //         border: "0.2px solid #ced9de",
                  //       }
                  //     : data?.status === "Expired"
                  //     ? { opacity: 0.6 }
                  //     : null),
                  // }}
                >
                  <li
                    onMouseEnter={() => handleMouseEnter(data?._id)}
                    onMouseLeave={() => handleMouseLeave(data?._id)}
                    style={renderRowStyle(indx, data?._id, isHover, rowId)}
                    onClick={() => onRowClick(data)}
                  >
                    <MyConfsCard
                      banner={data?.conference?.banner}
                      title={data?.conference?.title}
                      timezone={data?.conference?.timezone}
                      startDate={data?.conference?.startDate}
                      endDate={data?.conference?.endDate}
                      city={data?.conference?.city}
                      country={data?.conference?.country}
                      mode={data?.conference?.mode}
                      confId={data?.conference?._id}
                      onCardClick={() =>
                        navigate(`/attendee-credits/${data?.conference?._id}`)
                      }
                    />
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(data?._id)}
                    onMouseLeave={() => handleMouseLeave(data?._id)}
                    style={renderRowStyle(indx, data?._id, isHover, rowId)}
                    onClick={() => onRowClick(data)}
                  >
                    {data?.conference?.isAccredited
                      ? data.pendingRequest
                      : "None"}
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(data?._id)}
                    onMouseLeave={() => handleMouseLeave(data?._id)}
                    style={renderRowStyle(indx, data?._id, isHover, rowId)}
                    onClick={() => onRowClick(data)}
                  >
                    {data?.conference?.isAccredited
                      ? data.approvedRequest
                      : "None"}
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(data?._id)}
                    onMouseLeave={() => handleMouseLeave(data?._id)}
                    style={renderRowStyle(indx, data?._id, isHover, rowId)}
                    onClick={() => onRowClick(data)}
                  >
                    {data?.conference?.isAccredited ? data?.requested : "None"}
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(data?._id)}
                    onMouseLeave={() => handleMouseLeave(data?._id)}
                    style={renderRowStyle(indx, data?._id, isHover, rowId)}
                    onClick={() => onRowClick(data)}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <i>
                        <NextIcon className="icon-xl" />
                      </i>
                    </div>
                  </li>
                </Fragment>
              );
            })}
        </ul>
        <div className="flex-vc-sb">
          <div>
            <CustomPagination
              currentPageNumber={activePage}
              setCurrentPage={setActivePage}
              totalRecords={totalRecords}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={(data) => {
                loadPaginationData(data);
              }}
            />
          </div>
          <div>
            <PageLimitSelect
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              activePage={activePage}
              totalRecords={totalRecords}
              loadPaginationData={loadPaginationData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
