import { Link, useNavigate } from 'react-router-dom';
import FacebookWhiteCircle from '../icons/FacebookWhiteCircle';
import './footer.styles.scss';
import XLogo from '../icons/XLogo';
import LinkedInLogo from '../icons/LinkedInLogo';
import { openInNewTab } from '../../utility/commonUtil';

function Footer(props) {
  const navigate = useNavigate();
  return (
    <div className="footer bg-primary">
      <div className="footer-confemy-text">
        <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          Confemy
        </span>
      </div>
      <div className="flex">
        <div>
          <i
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openInNewTab(
                'https://www.facebook.com/profile.php?id=61556589157834'
              )
            }
          >
            <FacebookWhiteCircle className="social-whitecircle-icon" />
          </i>
        </div>
        <div className="social-white-circle mx-16">
          <i
            style={{ position: 'relative', paddingTop: '4px' }}
            onClick={() => openInNewTab('https://twitter.com/PortamedPvtLtd')}
          >
            <XLogo className="icon-xs" />
          </i>
        </div>
        <div>
          <i
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openInNewTab('https://www.linkedin.com/company/confemy')
            }
          >
            <LinkedInLogo className="social-whitecircle-icon" />
          </i>
        </div>
      </div>
      <ul>
        <li>
          <h4 style={{ color: 'white', marginBottom: 18 }}>Company</h4>
          <Link
            className="avenir-roman-18"
            to="/help"
            style={{ marginBottom: '12px' }}
          >
            Help
          </Link>
          <Link
            className="avenir-roman-18"
            to="/about"
            style={{ marginBottom: '12px' }}
          >
            About us
          </Link>
          <Link className="avenir-roman-18" to="/contact-us">
            Contact us
          </Link>
        </li>
        <li>
          <h4 style={{ color: 'white', marginBottom: 18 }}>Legal</h4>
          <Link
            className="avenir-roman-18"
            to="/terms-privacy-policy"
            style={{ marginBottom: '12px' }}
          >
            Terms & Privacy Policy
          </Link>
          <Link
            className="avenir-roman-18"
            to="/conditions"
            style={{ marginBottom: '12px' }}
          >
            Conditions
          </Link>
          <Link className="avenir-roman-18" to="/cancellation-refund">
            Cancellation & Refund
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
