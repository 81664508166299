import { useSelector } from 'react-redux';
import { getPrice } from '../../utility/commonUtil';

export default function PodcastBookingYearlyPrice() {
  const podcast = useSelector((state) => state.podcast.podcast);

  return (
    <div className="cep-card-bottombox">
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 ">Price</p>

        <p
          className="flex-vc caption-2-bold-cblack color-primary"
          style={{
            fontSize: 16,
            gap: '8px',
            wordBreak: 'break-word',
          }}
        >
          {getPrice(
            podcast?.currency,
            podcast?.monthlyPodcastPrice?.actualPrice,
            podcast?.monthlyPodcastPrice?.price,
            podcast?.currencySymbol
          )}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 color-primary">Offer</p>
          <p className="avenir-heavy-18 color-primary">
            {' '}
            <span>{podcast?.monthlyPodcastPrice?.discount}</span> % Off
          </p>
        </div>
      )}
      <div className="flex-vc-sb  mb-8">
        <p className="avenir-roman-18 color-primary">Service charge</p>
        <p className="avenir-heavy-18 color-primary">
          {' '}
          {podcast.currencySymbol}{' '}
          {podcast?.monthlyPodcastPrice?.actualPrice * (5 / 100)}
        </p>
      </div>
      {podcast?.monthlyPodcastPrice?.discount && (
        <div className="flex-vc-sb  mb-8">
          <p className="avenir-roman-18 color-primary">Total</p>
          <p className="avenir-heavy-18 color-primary">
            <span>{podcast?.currencySymbol} </span>
            <span>
              {podcast?.monthlyPodcastPrice?.actualPrice +
                podcast?.monthlyPodcastPrice?.actualPrice * (5 / 100)}
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
