import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  alertAction,
  localAlertAction,
} from "../../../redux/alert/alertAction";
import { loadMyOrganizationsSelectListAction } from "../../../redux/organization/myOrganizationsAction";
import { loadUserCustomConferenceCreditsTypeAction } from "../../../redux/user-profile/userProfileAction";
import api from "../../../utility/api";
import LocalAlert from "../../alert/LocalAlert";
import SubmitCancelButtonWithLoader from "../../button/SubmitCancelButtonWithLoader";
import MaterialTextInput from "../../formik/MaterialTextInput";
import TextError from "../../formik/TextError";

import { modalStyleformType1 } from "../../reselect/selectUtil";
import Select from "react-select";
import { getValue } from "../../../utility/commonUtil";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("Required"),
  host: yup.string().when("editMode", {
    is: false,
    then: yup
      .string()
      .required(
        "Choose organization or user itself under which credit will be added."
      ),
  }),
});

const ConferenceCreditsTypeForm = ({
  editMode,
  setEditMode,
  setShowConferenceCreditForm,
  editData,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const organizationsListForSelect = useSelector(
    (state) => state.myOrganizations.organizationsListForSelect
  );

  const options2 = [
    { value: "user", label: "User" },
    ...organizationsListForSelect,
  ];

  const onSubmit = async (values) => {
    const { name } = values;

    const data = {
      creditDetails: {
        name: name,
        userId: user?._id,
        host: values?.host === "user" ? "user" : "organization",
        orgId: values?.host != "user" ? values?.host : null,
      },
    };

    async function apiCall() {
      try {
        let response;
        if (editMode) {
          response = await api.patch(
            `/conferences/credits/userCustomCredit/${editData?.value}`,
            data
          );
        } else {
          response = await api.post(
            `/conferences/credits/userCustomCredits`,
            data
          );
        }

        if (response) {
          dispatch(
            loadUserCustomConferenceCreditsTypeAction(
              response.data.data.credits
            )
          );
          if (editMode) {
            setEditMode(false);
          } else {
            setShowConferenceCreditForm(false);
          }
          dispatch(alertAction(response.data.message, "success"));
        }
      } catch (err) {
        dispatch(
          localAlertAction(
            err?.response?.data?.message || "Something went wrong",
            "danger"
          )
        );
      }
    }

    apiCall();
  };

  const initialValues = {
    name: editData?.label || "",
    host: "",
    editMode: editMode ? true : false,
  };

  const loadMyOrgnizations = async (id) => {
    const url = `organizations/users/${id}?orgForConference=true`;
    try {
      const response = await api.get(url);

      if (response) {
        dispatch(
          loadMyOrganizationsSelectListAction(response.data?.data?.organization)
        );
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  useEffect(() => {
    loadMyOrgnizations(user?._id);
  }, [user?._id]);

  return (
    <>
      <div>
        <h2 className=" text-align-center color-primary mb-16">
          {editMode ? "Update credit type" : "Add credit type"}
        </h2>
        <LocalAlert />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="form-type-1">
                  {!editMode && (
                    <div>
                      <Select
                        value={getValue(options2, props.values.host, false)}
                        options={options2}
                        placeholder="Select org or User"
                        name="host"
                        onChange={(option) => {
                          props.setFieldValue("host", option.value);
                        }}
                        menuPortalTarget={document.body}
                        styles={modalStyleformType1}
                      />
                      <div className="mb-10">
                        {Boolean(props.errors.host) && (
                          <TextError>{props.errors.host}</TextError>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mb-16">
                    <MaterialTextInput
                      id="creditName"
                      type="text"
                      label="Name*"
                      name="name"
                      placeholder=" "
                    />
                  </div>
                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => {
                        if (editMode) {
                          setEditMode(false);
                        } else {
                          setShowConferenceCreditForm(false);
                        }
                      }}
                      cancelButtonClass="button button-green"
                      fullWidth={true}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ConferenceCreditsTypeForm;

ConferenceCreditsTypeForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func,
  setShowExternalCreditForm: PropTypes.func,
};
