import React from 'react';

function MediaDetailMobileIcon({ className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.775 2.25v19.5H18.25V2.25H5.775zm4.475 1.5H7.275v16.5h9.475V3.75h-3v1.5h-3.5v-1.5z"
        fill="#08415C"
      />
    </svg>
  );
}

export default MediaDetailMobileIcon;
