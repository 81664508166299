import { Fragment, useEffect, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { alertAction } from '../../redux/alert/alertAction';
import { createAudioCourseAction } from '../../redux/audio-course/audioCourseAction';
import { loadCreditTypesListAction } from '../../redux/list/listAction';
import api from '../../utility/api';
import { professions, subspecialties } from '../../utility/commonUtil';
import SubmitCancelButtonWithLoader from '../button/SubmitCancelButtonWithLoader';
import TextError from '../formik/TextError';
import SelectFormType1 from '../reselect/SelectFormType1';
import CheckTickIcon from '../icons/CheckTickIcon';
import CrossCancelIcon from '../icons/CrossCancelIcon';
import { modalStyleformType4 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue } from '../../utility/commonUtil';
import SpeakerForm from '../speaker/SpeakerForm';
import ModalX from '../modal/ModalX';
import CreditForm from '../Asset/UserConfemyCredits/CreditForm';
import Switch from '../switch/Switch';

const validationSchema = yup.object().shape({
  speakers: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        _id: yup
          .string()
          .trim()
          .required('Required. Add a valid speaker.')
          .test(
            'speakerFormTest',
            'Assign speaker using tickmark on right',
            (value, { parent }) => {
              if (parent?.isForm) {
                return false;
              } else {
                return true;
              }
            }
          ),
      })
    )
    .min(1, 'Add at least one speaker')
    .test(
      'duplicateSpeakerTest',
      function (value, { createError, path, parent }) {
        const hasDuplicates = parent?.speakers?.some((obj, index) => {
          return (
            parent?.speakers?.findIndex(
              (innerObj) => innerObj?._id === obj?._id
            ) !== index
          );
        });
        if (hasDuplicates)
          return createError({
            path,
            message: 'Can not add duplicate speakers',
          });
        else return true;
      }
    ),
  credits: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        value: yup
          .string()
          .required('Required. Add a valid credit.')
          .test(
            'creditFormTest',
            'Assign credit using tickmark on right',
            (value, { parent }) => {
              if (parent?.isForm) {
                return false;
              } else {
                return true;
              }
            }
          ),
        quantity: yup
          .number()
          .nullable()
          .required('Required')
          .min(1, 'Credit amount can not be less than 1'),
      })
    )
    .min(1, 'Add at least one credit')
    .test(
      'duplicateCreditTest',
      function (value, { createError, path, parent }) {
        const hasDuplicates = parent?.credits?.some((obj, index) => {
          return (
            parent?.credits?.findIndex(
              (innerObj) => innerObj?.value === obj?.value
            ) !== index
          );
        });
        if (hasDuplicates)
          return createError({
            path,
            message: 'Can not add duplicate credit',
          });
        else return true;
      }
    ),
  professions: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose professions')
    .compact(),
  specialities: yup
    .array()
    .of(yup.object())
    .min(1, 'Choose specialitities')
    .compact(),
  tags: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string(),
        tag: yup
          .string()
          .required('Required. Add a valid tag.')
          .max(50, 'Maximum 50 characters allowed')
          .min(4, 'Minimum 4 characters required')
          .test(
            'tagFormTest',
            'Assign tag using tickmark on right',
            (value, { parent }) => {
              if (parent?.isForm) {
                return false;
              } else {
                return true;
              }
            }
          ),
      })
    )
    .min(1, 'Add at least one tag')
    .test('duplicateTagTest', function (value, { createError, path, parent }) {
      const hasDuplicates = parent?.tags?.some((obj, index) => {
        return (
          parent?.tags?.findIndex((innerObj) => innerObj?.tag === obj?.tag) !==
          index
        );
      });
      if (hasDuplicates)
        return createError({
          path,
          message: 'Can not add duplicate tag',
        });
      else return true;
    }),

  mocPoint: yup.number().when('mocPointAdded', {
    is: true,
    then: yup
      .number()
      .typeError('Enter valid MOC point')
      .required('Required')
      .min(0, 'MOC point can only be 1 or more'),
  }),
});

export default function AudioCourseStep2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newAudioCourse = useSelector((state) => state.audio.newAudioCourse);
  const orgId = newAudioCourse?.hostedBy?.organization?._id;
  const userId = newAudioCourse?.hostedBy?.user?._id;
  const { creditTypesList } = useSelector((state) => state.list);
  const [speakerList, setSpeakerList] = useState([]);
  const [showSpeakerForm, setShowSpeakerForm] = useState(false);
  const [showCreditForm, setShowCreditForm] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // to programmatecally close select menu on clicking on noptions button
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  function updateSpeakerList(newSpeaker) {
    setSpeakerList((prev) => [newSpeaker, ...prev]);
  }

  async function onSubmit(values, actions) {
    if (newAudioCourse?.completedStep1) {
      const {
        professions,
        specialities,
        tags,
        credits,
        speakers,
        mocPoint,
        mocPointAdded,
      } = values;
      const formData = {
        mediaDetails: {
          professions,
          specialities,
          tags,
          credits,
          speakers,
          mediaId: newAudioCourse?._id,
          mocPoint,
          mocPointAdded,
        },
      };

      try {
        const response = await api.post('audioCourses/step2', formData);

        if (response) {
          dispatch(createAudioCourseAction(response.data.data.media));
          navigate('/dashboard/create-ac/step-3');
          dispatch(alertAction(response.data.message, 'success'));
        }
      } catch (err) {
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
      }
    } else {
      dispatch(alertAction('Complete step-1 first', 'danger'));
    }
  }
  const initialValues = {
    professions: newAudioCourse?.professions || [],
    specialities: newAudioCourse?.specialities || [],
    tags: !newAudioCourse?.tags?.length > 0 ? [] : newAudioCourse?.tags,
    credits: newAudioCourse?.courseCredits || [],
    speakers: newAudioCourse?.speakersData || [],
    mocPoint: newAudioCourse?.mocPoint || 0,
    mocPointAdded: newAudioCourse?.mocPointAdded || false,
  };

  // load credit types from backend
  async function getCreditTypes() {
    // const url = `conferences/credits`
    const orgGetCreditsUrl = `common/conferences/credits?type=org&orgId=${orgId}`;
    const userGetCreditsUrl = `common/conferences/credits?type=user&userId=${userId}`;
    try {
      const response = await api.get(
        newAudioCourse?.host === 'organization'
          ? orgGetCreditsUrl
          : userGetCreditsUrl
      );
      if (response) {
        dispatch(loadCreditTypesListAction(response.data.data.credits));
      }
    } catch (err) {
      if (err)
        dispatch(
          alertAction(
            err?.response?.data?.message || 'Something went wrong',
            'danger'
          )
        );
    }
  }

  async function loadSpeakers(host, id) {
    const url = `speakers?_id=${id}&type=${host}`;
    try {
      const response = await api.get(url);
      if (response) {
        setSpeakerList(response.data.data.speakers);
      }
    } catch (err) {
      if (err) {
        dispatch(alertAction(err.response?.data?.message, 'danger'));
      }
    }
  }

  useEffect(() => {
    if (newAudioCourse?.host) {
      getCreditTypes();
    }
  }, []);

  useEffect(() => {
    if (newAudioCourse?.completedStep1) {
      loadSpeakers(
        newAudioCourse?.host,
        newAudioCourse?.host === 'organization' ? orgId : userId
      );
    }
  }, []);

  return (
    <main className="create-event-wrap">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnBlur={false}
        // validateOnChange={false}
      >
        {(props) => {
          return (
            <form
              className="form-type-4"
              autoComplete="off"
              onSubmit={props.handleSubmit}
            >
              <div className="create-event-grid">
                <div
                // first limb of the grid
                >
                  <p
                    className="body-regular-gray3 mb-24"
                    style={{ color: '#aabdc7' }}
                  >
                    All mandatory fields are makred by *
                  </p>
                  <h2 className="color-primary mb-36">Details</h2>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">Speakers *</h4>
                    <p className="caption-1-regular-gray3 mb-16">
                      Add one or more speakers
                    </p>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      If there is no speaker in the dropdown, one can add a new
                      speaker using Assets tab in the menu on left or using +
                      New speaker button at the bottom of selection menu. Then
                      selct and assign spearker(s) to the course. One can edit
                      the speakers through Assests tab.
                    </p>

                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="speakers"
                        render={(arrayHelpers) => {
                          const speakers = arrayHelpers.form.values.speakers;
                          let indxOfLastItem = 0;
                          if (speakers?.length > 0) {
                            indxOfLastItem = speakers?.length - 1;
                          }
                          return (
                            <Fragment>
                              <div
                                // section above add button
                                className="mb-4"
                              >
                                {speakers?.map((item, index) => {
                                  const speakerObj = speakerList?.find(
                                    (e) => e?._id === item?._id
                                  );

                                  return (
                                    <motion.div
                                      // each item
                                      className="mb-8"
                                      key={index}
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div
                                        className={
                                          !item.isForm
                                            ? 'form-type-4-box position-relative'
                                            : 'display-none '
                                        }
                                      >
                                        <div>
                                          <span
                                            style={{
                                              paddingRight: '8px',
                                              borderRight: '2px solid #08415c',
                                            }}
                                          >
                                            {speakerObj?.label}
                                          </span>
                                          <span
                                            style={{
                                              padding: '0 8px',
                                              borderRight: '2px solid #08415c',
                                            }}
                                          >
                                            {speakerObj?.degree}{' '}
                                          </span>
                                          <span
                                            style={{
                                              paddingLeft: '8px',
                                            }}
                                          >
                                            {speakerObj?.designation}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            item.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !item.isForm
                                            ? 'display-none'
                                            : 'position-relative'
                                        }
                                      >
                                        <Select
                                          options={speakerList}
                                          label="Speakers"
                                          name={`speakers.${index}._id`}
                                          value={speakerList.find(
                                            (item) =>
                                              item?._id ===
                                              props.values.speakers[index]._id
                                          )}
                                          onChange={(value) => {
                                            props.setFieldValue(
                                              `speakers.${index}._id`,
                                              value?._id
                                            );
                                          }}
                                          placeholder="Select Speaker"
                                          menuPortalTarget={document.body}
                                          styles={modalStyleformType4}
                                          noOptionsMessage={() => {
                                            return (
                                              <div className="sns-buttons-wrap">
                                                <span
                                                  type="button"
                                                  className="conf-add-newcredit-btn caption-1-heavy-primary m-4"
                                                  onClick={() => {
                                                    handleMenuClose();
                                                    setShowSpeakerForm(true);
                                                  }}
                                                >
                                                  + New speaker
                                                </span>
                                              </div>
                                            );
                                          }}
                                          menuIsOpen={menuIsOpen}
                                          onMenuOpen={handleMenuOpen}
                                          onMenuClose={handleMenuClose}
                                        />
                                        {/* <ErrorMessage
                                          name={`speakers.${index}._id`}
                                          component={TextError}
                                        /> */}
                                        <div
                                          className={
                                            !item.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onMouseDown={() => {
                                              let myspeakers = speakers?.map(
                                                (e) =>
                                                  e.uuid === item.uuid
                                                    ? {
                                                        ...e,
                                                        isForm: false,
                                                      }
                                                    : e
                                              );
                                              props
                                                .setFieldValue(
                                                  'speakers',
                                                  myspeakers
                                                )
                                                .then(() => {
                                                  props.setFieldTouched(
                                                    `speakers.${index}._id`
                                                  );
                                                  props.validateField(
                                                    'speakers'
                                                  );
                                                });
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <ErrorMessage
                                        name={`speakers.${index}._id`}
                                        component={TextError}
                                      />
                                    </motion.div>
                                  );
                                })}
                              </div>
                              {/* speakers error */}
                              <div className="mb-16">
                                {props.touched.speakers &&
                                  Boolean(props.errors.speakers) &&
                                  (typeof props.errors.speakers === 'string' ? (
                                    <TextError>
                                      {props.errors.speakers}
                                    </TextError>
                                  ) : null)}
                              </div>
                              <div
                                // section for add button
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    props.errors?.speakers ===
                                      'Can not add duplicate speakers' ||
                                    speakers[indxOfLastItem]?._id === '' ||
                                    speakers[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      _id: '',
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add speakers
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                  </div>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">Credits</h4>
                    <p
                      className="caption-1-regular-gray3 mb-8"
                      style={{ color: '#aabdc7' }}
                    >
                      If you offer credits for the course then add credits type
                      and it's amount.
                    </p>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      One can add new credit type in Assests tab in menu on
                      left. Or on searching credit here, if its not in the menu
                      options, + New credit button will show up, use that to add
                      new credit.
                    </p>

                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="credits"
                        render={(arrayHelpers) => {
                          const credits = arrayHelpers.form.values.credits;
                          let indxOfLastItem = 0;
                          if (credits?.length > 0) {
                            indxOfLastItem = credits?.length - 1;
                          }
                          return (
                            <Fragment>
                              <div
                                // section above add button
                                className="mb-4"
                              >
                                {credits?.map((credit, index) => {
                                  const creditObj = getValue(
                                    creditTypesList,
                                    credit.value,
                                    false
                                  );
                                  return (
                                    <motion.div
                                      // each credit
                                      className="mb-8"
                                      key={index}
                                      layout
                                      initial={{ scale: 0.5, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      exit={{ scale: 0.5, opacity: 0 }}
                                      transition={{
                                        type: 'spring',
                                        duration: 0.5,
                                      }}
                                    >
                                      <div
                                        className={
                                          !credit.isForm
                                            ? 'form-type-4-box position-relative'
                                            : 'display-none '
                                        }
                                      >
                                        <div>
                                          <span
                                            style={{
                                              paddingRight: '12px',
                                              borderRight: '2px solid #08415c',
                                            }}
                                          >
                                            {creditObj?.label}
                                          </span>
                                          <span className="ml-8">
                                            {credit?.quantity}{' '}
                                          </span>
                                          <span>Credits</span>
                                        </div>
                                        <div
                                          className={
                                            credit.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          !credit.isForm
                                            ? 'display-none'
                                            : 'ce-points-grid position-relative'
                                        }
                                      >
                                        <Select
                                          options={creditTypesList}
                                          label="Credit Type"
                                          name={`credits.${index}.value`}
                                          value={getValue(
                                            creditTypesList,
                                            props.values.credits[index].value,
                                            false
                                          )}
                                          onChange={(value) => {
                                            props.setFieldValue(
                                              `credits.${index}.value`,
                                              value?.value
                                            );
                                          }}
                                          placeholder="Select Credit Type"
                                          menuPortalTarget={document.body}
                                          styles={modalStyleformType4}
                                          noOptionsMessage={() => {
                                            return (
                                              <div className="sns-buttons-wrap">
                                                <span
                                                  type="button"
                                                  className="conf-add-newcredit-btn caption-1-heavy-primary m-4"
                                                  onClick={() => {
                                                    handleMenuClose();
                                                    setShowCreditForm(true);
                                                  }}
                                                >
                                                  + New credit
                                                </span>
                                              </div>
                                            );
                                          }}
                                          menuIsOpen={menuIsOpen}
                                          onMenuOpen={handleMenuOpen}
                                          onMenuClose={handleMenuClose}
                                        />
                                        <Field
                                          className="dark-placeholder"
                                          type="number"
                                          min={1}
                                          name={`credits.${index}.quantity`}
                                          placeholder="Credit Amount *"
                                        />
                                        {/* <ErrorMessage
                                          name={`credits.${index}.value`}
                                          component={TextError}
                                        />
                                        <ErrorMessage
                                          name={`credits.${index}.quantity`}
                                          component={TextError}
                                        /> */}
                                        <div
                                          className={
                                            !credit.isForm
                                              ? 'display-none'
                                              : 'ce-hanging-buttons-wrap'
                                          }
                                        >
                                          <i
                                            style={{
                                              // right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="mr-6"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>
                                          <i
                                            style={{ cursor: 'pointer' }}
                                            onMouseDown={() => {
                                              let mycredits = credits?.map(
                                                (e) =>
                                                  e?.uuid === credit?.uuid
                                                    ? {
                                                        ...e,
                                                        isForm: false,
                                                      }
                                                    : e
                                              );
                                              props
                                                .setFieldValue(
                                                  'credits',
                                                  mycredits
                                                )
                                                .then(() => {
                                                  props.setFieldTouched(
                                                    `credits.${index}.value`
                                                  );
                                                  props.setFieldTouched(
                                                    `credits.${index}.quantity`
                                                  );
                                                  props.validateField(
                                                    'credits'
                                                  );
                                                });
                                            }}
                                          >
                                            <CheckTickIcon className="icon-size" />
                                          </i>
                                        </div>
                                      </div>
                                      <div className="ce-points-grid">
                                        <ErrorMessage
                                          name={`credits.${index}.value`}
                                          component={TextError}
                                        />
                                        <ErrorMessage
                                          name={`credits.${index}.quantity`}
                                          component={TextError}
                                        />
                                      </div>
                                    </motion.div>
                                  );
                                })}
                              </div>
                              <div
                                className="mb-16"
                                //section error for tags
                              >
                                {props.touched.credits &&
                                  Boolean(props.errors.credits) &&
                                  (typeof props.errors.credits === 'string' ? (
                                    <TextError>
                                      {props.errors.credits}
                                    </TextError>
                                  ) : null)}
                              </div>
                              <div
                                // section for add button
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    props.errors?.credits ===
                                      'Can not add duplicate credit' ||
                                    credits[indxOfLastItem]?.value === '' ||
                                    credits[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      value: '',
                                      quantity: 0,
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add credits
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                  </div>
                  <div className="mb-24">
                    <h4 className="avenir-20-500 mb-8">MOC Point</h4>
                    <p
                      className="caption-1-regular-gray3 mb-8"
                      style={{ color: '#aabdc7' }}
                    >
                      MOC points are points earned through Maintenance of
                      Certification (MOC) activities to maintain board
                      certification.
                    </p>
                    <Switch
                      id="preview"
                      name="mocPointAdded"
                      value={props.values.mocPointAdded}
                      checked={props.values.mocPointAdded}
                      onChange={props.handleChange}
                      disable={false}
                    />
                  </div>
                  {props?.values?.mocPointAdded && (
                    <div className="mb-24">
                      <div className="material-textfield">
                        <Field
                          id="mocPoint"
                          type="number"
                          name="mocPoint"
                          min={0}
                          placeholder=" "
                        />
                        <label>MOC Point</label>
                      </div>
                      <ErrorMessage name="mocPoint" component={TextError} />
                    </div>
                  )}
                  <div className="mb-24">
                    <h4 className="avenir-20-500 mb-8">Professions *</h4>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      Add all professions to which this course is applicable.
                    </p>
                    <SelectFormType1
                      options={professions}
                      label="professions"
                      value={props.values.professions}
                      onChange={(value) => {
                        return props.setFieldValue('professions', value);
                      }}
                      placeholder="Choose Professions"
                      isMulti={true}
                    />
                    <div>
                      {props.touched.professions &&
                        Boolean(props.errors.professions) && (
                          <TextError>{props.errors.professions}</TextError>
                        )}
                    </div>
                  </div>
                  <div className="mb-24">
                    <h4 className="avenir-20-500 mb-8">Specialties *</h4>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      Add all specialties to which this course is applicable.
                    </p>
                    <SelectFormType1
                      options={subspecialties}
                      label="specialities"
                      name="specialities"
                      placeholder="Choose specialities"
                      value={props.values.specialities}
                      onChange={(value) =>
                        props.setFieldValue('specialities', value)
                      }
                      isMulti={true}
                    />
                    <div>
                      {props.touched.specialities &&
                        Boolean(props.errors.specialities) && (
                          <TextError>{props.errors.specialities}</TextError>
                        )}
                    </div>
                  </div>
                  <div className="mb-40">
                    <h4 className="avenir-20-500 mb-8">
                      Improve Searchability with Tags *
                    </h4>
                    <p
                      className="caption-1-regular-gray3 mb-8"
                      style={{ color: '#aabdc7' }}
                    >
                      Tag is a key word or combination of words if searched by
                      user, will bring up this audio course.
                    </p>
                    <p
                      className="caption-1-regular-gray3 mb-16"
                      style={{ color: '#aabdc7' }}
                    >
                      Add multiple tags.
                    </p>
                    <AnimatePresence initial={false} mode="wait">
                      <FieldArray
                        name="tags"
                        render={(arrayHelpers) => {
                          const tags = arrayHelpers.form.values.tags;
                          let indxOfLastItem = 0;
                          if (tags?.length > 0) {
                            indxOfLastItem = tags?.length - 1;
                          }
                          return (
                            <Fragment>
                              {tags?.length > 0 && (
                                <div
                                  // grid for tags
                                  className="ce-points-grid mb-4"
                                >
                                  {tags?.map((tag, index) => {
                                    return (
                                      <motion.div
                                        // grid item
                                        key={index}
                                        className={
                                          tag.isForm
                                            ? 'full-width-griditem'
                                            : ''
                                        }
                                        layout
                                        initial={{ scale: 0.5, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        exit={{ scale: 0.5, opacity: 0 }}
                                        transition={{
                                          type: 'spring',
                                          duration: 0.5,
                                        }}
                                      >
                                        <div className="position-relative">
                                          <Field
                                            disabled={!tag.isForm}
                                            style={{ paddingRight: 36 }}
                                            type="text"
                                            name={`tags.${index}.tag`}
                                            placeholder="improve searchability with tags *"
                                            // validate={validateTag}
                                            autoFocus
                                          />
                                          <i
                                            style={{
                                              right: 8,
                                              cursor: 'pointer',
                                            }}
                                            className="right-input-icon"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <CrossCancelIcon className="icon-size" />
                                          </i>

                                          <div
                                            className={
                                              !tag.isForm
                                                ? 'display-none'
                                                : 'ce-hanging-buttons-wrap'
                                            }
                                          >
                                            <i
                                              style={{ cursor: 'pointer' }}
                                              onMouseDown={() => {
                                                const myTags = tags?.map(
                                                  (item) =>
                                                    item.uuid === tag.uuid
                                                      ? {
                                                          ...item,
                                                          isForm: false,
                                                        }
                                                      : item
                                                );
                                                arrayHelpers.form
                                                  .setFieldValue('tags', myTags)
                                                  .then(() => {
                                                    props.setFieldTouched(
                                                      `tags.${index}.tag`
                                                    );
                                                    props.validateField('tags');
                                                  });
                                              }}
                                            >
                                              <CheckTickIcon className="icon-size" />
                                            </i>
                                          </div>
                                        </div>
                                        <ErrorMessage
                                          name={`tags.${index}.tag`}
                                          component={TextError}
                                        />
                                      </motion.div>
                                    );
                                  })}
                                </div>
                              )}
                              <div className="mb-16">
                                {props.touched.tags &&
                                  Boolean(props.errors.tags) &&
                                  (typeof props.errors.tags === 'string' ? (
                                    <TextError>{props.errors.tags}</TextError>
                                  ) : null)}
                              </div>
                              <div
                                // outside kyepoints grid
                                // this div controls size of the button
                                style={{
                                  width: '24rem',
                                }}
                              >
                                <motion.button
                                  disabled={
                                    props.errors?.tags ===
                                      'Can not add duplicate tag' ||
                                    tags[indxOfLastItem]?.tag?.length > 50 ||
                                    tags[indxOfLastItem]?.tag === '' ||
                                    tags[indxOfLastItem]?.isForm
                                  }
                                  className="ce-insert-button flex-vc"
                                  whileTap={{ scale: 0.95 }}
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      uuid: uuid(),
                                      tag: '',
                                      isForm: true,
                                    })
                                  }
                                >
                                  + Add tags
                                </motion.button>
                              </div>
                            </Fragment>
                          );
                        }}
                      />
                    </AnimatePresence>
                  </div>

                  <div>
                    <SubmitCancelButtonWithLoader
                      isSubmitting={props.isSubmitting}
                      onCancel={() => props.resetForm({})}
                      fullWidth={true}
                    />
                  </div>
                </div>
                <div className="create-event-res">{/* keep empty */}</div>
              </div>
            </form>
          );
        }}
      </Formik>
      {showSpeakerForm && (
        <ModalX
          onDismiss={() => setShowSpeakerForm(false)}
          open={showSpeakerForm}
        >
          <div className="modalx-container-sm pl-16 pr-16 pb-32">
            <SpeakerForm
              setShowSpeakerForm={setShowSpeakerForm}
              updateSpeakerList={updateSpeakerList}
              completedStep1={newAudioCourse?.completedStep1}
              host={newAudioCourse?.host}
              orgId={orgId}
              userId={userId}
            />
          </div>
        </ModalX>
      )}
      {showCreditForm && (
        <ModalX
          onDismiss={() => setShowCreditForm(false)}
          open={showCreditForm}
        >
          <div className="modalx-container-sm pl-16 pr-16 pb-32">
            <CreditForm
              setShowCreditForm={setShowCreditForm}
              completedStep1={newAudioCourse?.completedStep1}
              host={newAudioCourse?.host}
              orgId={orgId}
              userId={userId}
            />
          </div>
        </ModalX>
      )}
    </main>
  );
}
