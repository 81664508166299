import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import api from "../../utility/api";
import { alertAction } from "../../redux/alert/alertAction";
import PurchasedVideoList from "../../components/attendee-video-courses/PurchasedVideoList";
import PageLimitSelect from "../../components/pagination/PageLimitSelect";
import CustomPagination from "../../components/pagination/CustomPagination";
import SearchIcon from "../../components/icons/SearchIcon";
import { scrollToTop } from "../../utility/commonUtil";

export default function PurchasedVideoPage() {
  const [courses, setCourses] = useState([]);
  const [searchText, setSearchText] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const [currentPageNumber, setCurrentPageNumber] = useState({
    currentPage: 1,
  });
  const [activePage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const dispatch = useDispatch();

  const getPurchasedMedia = async (searchText, activePage, pageLimit) => {
    try {
      const response = await api.get(
        `/courses/videoCourse/purchase/users/${user._id}?searchText=${searchText}&activePage=${activePage}&pageLimit=${pageLimit}`
      );

      if (response.data.data?.currentPage === 1) {
        setCurrentPageNumber({ currentPage: response.data.data?.currentPage });
        setCurrentPage(response.data.data?.currentPage);
      }

      setTotalRecords(response.data?.data?.totalrecords);
      setCourses(response.data.data.videoCourses);
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || "Something went wrong",
          "danger"
        )
      );
    }
  };

  const loadPaginationData = (data) => {
    const { currentPage, pageLimit } = data;

    getPurchasedMedia(searchText, currentPage, pageLimit);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    getPurchasedMedia(searchText, activePage, pageLimit);
  }, [user?._id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="form-type-3 mb-40">
        <div className="position-relative">
          <input
            type="text"
            id="ab-searchtickets"
            placeholder="Search Video Courses"
            name="searchText"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              getPurchasedMedia(e.target.value, activePage, pageLimit);
            }}
            autoComplete="off"
            style={{ paddingLeft: 48 }}
          />
          <i
            className={
              searchText?.length > 0 ? "display-none" : "conf-search-input-icon"
            }
          >
            <SearchIcon width="2.4rem" height="2.4rem" />
          </i>
        </div>
      </div>
      <PurchasedVideoList courses={courses} />
      <div className="flex-vc-sb">
        <div>
          <CustomPagination
            currentPageNumber={currentPageNumber}
            setCurrentPage={setCurrentPageNumber}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={currentPageNumber}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
      </div>
    </div>
  );
}
