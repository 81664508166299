export const GET_VIDEO_COURSE = 'GET_VIDEO_COURSE';
// export const REMOVE_VIDEO_COURSE = 'REMOVE_VIDEO_COURSE';
export const VIDEO_COURSE_ERROR = 'VIDEO_COURSE_ERROR';
export const CREATE_VIDEO_COURSE = 'CREATE_VIDEO_COURSE';
export const REMOVE_NEWVC_STATE = 'REMOVE_NEWVC_STATE';
export const LOAD_INCOMPLETE_VCS = 'LOAD_INCOMPLETE_VCS';
export const LOAD_INCOMPLETE_VC = 'LOAD_INCOMPLETE_VC';
export const LOAD_ALL_MY_VCS = 'LOAD_ALL_MY_VCS';
export const VC_SEARCH_DONE = 'VC_SEARCH_DONE';
export const VC_SEARCH_INITIATED = 'VC_SEARCH_INITIATED';
export const GET_SINGLE_VIDEO_COURSE = 'GET_SINGLE_VIDEO_COURSE';
export const LOAD_USER_VIDEO_COURSE_CREDIT = 'LOAD_USER_VIDEO_COURSE_CREDIT';
export const ADD_FILE_DURATION = 'ADD_FILE_DURATION';

// VC is video course
