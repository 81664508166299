import { useNavigate } from 'react-router-dom';
import CourseCardWithOptions from '../attendee-video-courses/CourseCardWithOptions';
import { useEffect } from 'react';
import { scrollToTop } from '../../utility/commonUtil';

export default function PurchasedPodcasts({ courses, type }) {
  const navigate = useNavigate();

  // const optionsComp = [
  //   <div className={css.opt}>
  //     <Link to="/" className={css.txtBox}>
  //       <span className={css.iconBox}>
  //         <img src={shareIcon} alt="icon" className={css.icon} />
  //       </span>
  //       <span className={css.txt}>Share</span>
  //     </Link>
  //     <Link to="/" className={css.txtBox}>
  //       <span className={css.iconBox}>
  //         <img src={starIcon} alt="icon" className={css.icon} />
  //       </span>
  //       <span className={css.txt}>Favorite</span>
  //     </Link>
  //   </div>,
  // ];

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="flex flex-wrap" style={{ gap: '2rem' }}>
      {courses.map((course) => {
        return (
          <CourseCardWithOptions
            Key={course._id}
            data={course}
            isOptions={true}
            openPage={() => navigate(`/podcasts/${course._id}`)}
            // options={optionsComp}
            id={course?._id}
            type={type}
          />
        );
      })}
    </div>
  );
}
