import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LocalAlert from '../alert/LocalAlert';
import TextError from '../formik/TextError';
import { modalStyleformType1 } from '../reselect/selectUtil';
import Select from 'react-select';
import { getValue, getQuery } from '../../utility/commonUtil';
import {
  loadUserCreditConferencesAction,
  loadUserTotalCreditsAction,
} from '../../redux/user-profile/userProfileAction';
import { localAlertAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';

const validationSchema = yup.object().shape({
  creditId: yup.string().required('Required.'),
  creditCustomQuantity: yup.number().when(
    'creditQuantity',
    (creditQuantity, schema) =>
      creditQuantity &&
      schema
        .min(1, 'Quantity must me greater than 0')
        // .max(
        //   creditQuantity,
        //   `Quantity should less than or equal to ${creditQuantity}`
        // )
        .required('Required')
  ),
});

export default function RequestCreditForn({
  confStartDate,
  confEndDate,
  pageLimit,
  page,
  setCreditsCount,
  data,
  setShowModal,
  setRowData,
  getConfs,
}) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  let credit = data?.conference?.credits?.map((credit) => ({
    label: credit.creditId.name,
    value: credit.creditId._id,
    quantity: credit.quantity,
  }));

  const initialValues = {
    creditOptions: credit,
    creditQuantity: credit?.[0]?.quantity,
    creditCustomQuantity: credit?.[0]?.quantity,
    creditId: credit?.[0]?.value,
  };

  const onSubmit = async (values, action) => {
    let formData = {
      atteendeeDetails: {
        creditRequest: true,
        creditId: values.creditId,
        creditQuantity: values.creditCustomQuantity,
      },
    };

    const parameters = [
      { name: 'startDate', value: confStartDate?.toISOString() },
      { name: 'endDate', value: confEndDate?.toISOString() },
    ];
    const query = getQuery(parameters);
    const baseURL = `attendees/credits/users/${data?._id}?page=${page}&limit=${pageLimit}&userId=${user?._id}&getAllCreditType=true`;
    const url = query?.length > 0 ? baseURL + '&' + query : baseURL;

    try {
      let response = await api.patch(url, formData);
      setRowData(response.data.data.attendee);
      setCreditsCount(response.data.data?.creditCount);
      dispatch(loadUserCreditConferencesAction(response.data.data.allCredits));
      dispatch(loadUserTotalCreditsAction(response.data.data.countData));
    } catch (error) {
      dispatch(localAlertAction(error.response.data.message, 'danger'));
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="add-documents-wrap">
        <h3 className="color-primary mb-24"> Request Credit Certificate</h3>
        <LocalAlert />
        <div>
          <p
            style={{ fontSize: 16 }}
            className="body-regular-a-gray3 color-primary mb-4"
          >
            Credits requested by user
          </p>
          <div className="grid-table-wrap mb-16">
            <ul className="ucr-gridtable">
              <li>Credit</li>
              <li>Amount</li>
              <li>Date</li>
              {data?.requestedCredits?.map((credit, indx) => {
                return (
                  <Fragment key={indx}>
                    <li>{credit.credit.name}</li>
                    <li>{credit.quantity}</li>
                    <li>{new Date(credit?.requestedDate).toDateString()}</li>
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="mb-16">
          <p
            style={{ fontSize: 16 }}
            className="body-regular-a-gray3 color-primary mb-4"
          >
            Credits already approved
          </p>
          <div className="grid-table-wrap">
            <ul className="ucr-gridtable">
              <li>Credit</li>
              <li>Amount</li>
              <li>Date</li>
              {data?.approvedCredits?.map((credit, indx) => {
                return (
                  <Fragment key={indx}>
                    <li>{credit.credit.name}</li>
                    <li>{credit.quantity}</li>
                    <li>{new Date(credit?.approvedDate).toDateString()}</li>
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
        <form
          className="form-type-1 mb-16"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <Select
            label="creditId"
            options={formik?.values?.creditOptions}
            name="creditId"
            onChange={(value) => {
              formik.setFieldValue('creditId', value?.value);
              formik.setFieldValue('creditCustomQuantity', value?.quantity);
              formik.setFieldValue('creditQuantity', value?.quantity);
            }}
            placeholder="Select Credit*"
            value={getValue(
              formik?.values?.creditOptions,
              formik.values.creditId,
              false
            )}
            menuPortalTarget={document.body}
            styles={modalStyleformType1}
          />
          <div className="mb-10">
            {formik.touched.creditId && Boolean(formik.errors.creditId) && (
              <TextError>{formik.errors.creditId}</TextError>
            )}
          </div>
          <div className="mb-16">
            <div className="material-textfield">
              <input
                id="quantity"
                type="number"
                name="creditCustomQuantity"
                value={formik.values.creditCustomQuantity}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Credit Quantity*</label>
            </div>
            <div>
              {formik.touched.creditCustomQuantity &&
                Boolean(formik.errors.creditCustomQuantity) && (
                  <TextError>{formik.errors.creditCustomQuantity}</TextError>
                )}
            </div>
          </div>

          <div>
            <button type="submit" className="button button-primary">
              Request
            </button>

            <button
              type="button"
              onClick={() => {
                getConfs();
                setShowModal(false);
              }}
              className="button button-green ml-8"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
