import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { alertAction } from "../../redux/alert/alertAction";
import SingleImageUploader from "../image-uploader/SingleImageUploader";

import api from "../../utility/api";
import TextError from "../formik/TextError";
import DeleteIcon from "../icons/DeleteIcon";

import "./speakerForm.styles.scss";
import SubmitButtonWithLoader from "../button/SubmitButtonWithLoader";

const validationSchema = yup.object({
  firstName: yup.string().trim().max(25).required("Required"),
  lastName: yup.string().trim().max(25).required("Required"),
  degree: yup.string().trim().required("Required"),
  designation: yup.string().trim().required("Required"),
});

const initialValues = {
  images: [],
  firstName: "",
  lastName: "",
  degree: "",
  designation: "",
};

export default function SpeakerForm({
  setShowSpeakerForm,
  updateSpeakerList,
  completedStep1,
  host,
  orgId,
  userId,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const newConference = useSelector((state) => state.conference.newConference);

  async function onSubmit(values, actions) {
    if (!completedStep1) {
      dispatch(alertAction("Complete step-1 first", "danger"));
      return;
    }
    const formData = {
      speakerDetails: {
        data: values.images,
        firstName: values.firstName,
        lastName: values.lastName,
        degree: values.degree,
        designation: values.designation,
        createdBy: host,
        userId: userId,
        organizationId: orgId,
      },
    };

    if (values.images?.length > 0) {
      const formDataObj = new FormData();
      formDataObj.append("file", values.images[0]);
      try {
        const imagesResponse = await api.post("fileUploads", formDataObj);
        if (imagesResponse) {
          formData.speakerDetails.data = imagesResponse.data.data;
          const response = await api.post("/speakers", formData);
          if (response) {
            // update the speaker list
            updateSpeakerList(response.data.data.newSpeaker);
            actions.resetForm({ values: initialValues });
            setShowSpeakerForm(false);
          }
        }
      } catch (err) {
        actions.setFieldError("logos", err.response?.data.message);
      }
    } else {
      try {
        const response = await api.post("/speakers", formData);
        if (response) {
          // update the speaker list
          updateSpeakerList(response.data.data.newSpeaker);
          actions.resetForm({ values: initialValues });
          setShowSpeakerForm(false);
        }
      } catch (err) {
        if (err) {
          dispatch(
            alertAction(
              err?.response?.data?.message || "Something went wrong",
              "danger"
            )
          );
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  function setFormikFieldValue(field, value) {
    formik.setFieldValue(field, value);
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      formik.values.images?.forEach((file) =>
        URL.revokeObjectURL(file.Location)
      );
  }, [formik.values.images]);

  console.log("completed step 1", completedStep1);

  return (
    <>
      <h2 className="color-primary text-align-center">Add Speaker</h2>
      <form
        className="form-type-1"
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <div className="speaker-dzimg-container mb-40">
          {formik.values?.images?.length > 0 ? (
            formik.values.images?.map((image) => (
              <div key={image.Location} className="speakerimage-container">
                <div className="speakerimage-wrap">
                  <img
                    className="speakerimage"
                    alt="speaker image"
                    src={image.Location}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(image.Location);
                    }}
                  />
                </div>
                <div className="speakerimage-overlay"></div>
                <div
                  onClick={() => {
                    const imagesLeft = formik.values.images?.filter(
                      (e) => e !== image
                    );
                    formik.setFieldValue("images", imagesLeft);
                  }}
                  className="speakerimage-delete-circle"
                >
                  <DeleteIcon className="icon-size" />
                </div>
              </div>
            ))
          ) : (
            // className="speaker-image-dropzone-container" controls the size if SingleImageUploader
            <div className="speaker-image-dropzone-wrap">
              <SingleImageUploader
                fieldName="images"
                setFormikFieldValue={setFormikFieldValue}
                // dropzoneContentType="speakerImage"
              />
            </div>
          )}
        </div>
        <div className="speaker-form-grid">
          <div>
            <div className="material-textfield">
              <input
                id="firstName"
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>First Name*</label>
            </div>
            <div>
              {formik.touched.firstName && Boolean(formik.errors.firstName) && (
                <TextError>{formik.errors.firstName}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="lastName"
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Last Name*</label>
            </div>
            <div>
              {formik.touched.lastName && Boolean(formik.errors.lastName) && (
                <TextError>{formik.errors.lastName}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="degree"
                type="text"
                name="degree"
                value={formik.values.degree}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Degree*</label>
            </div>
            <div>
              {formik.touched.degree && Boolean(formik.errors.degree) && (
                <TextError>{formik.errors.degree}</TextError>
              )}
            </div>
          </div>
          <div>
            <div className="material-textfield">
              <input
                id="designation"
                type="text"
                name="designation"
                value={formik.values.designation}
                onChange={formik.handleChange}
                placeholder=" "
              />
              <label>Designation*</label>
            </div>
            <div>
              {formik.touched.designation &&
                Boolean(formik.errors.designation) && (
                  <TextError>{formik.errors.designation}</TextError>
                )}
            </div>
          </div>

          <div>
            <SubmitButtonWithLoader
              isSubmitting={formik.isSubmitting}
              text="Submit"
              className="button button-green"
              fullWidth={true}
            />
          </div>
          <div>
            <button
              style={{ width: "100%" }}
              className="button button-primary"
              type="button"
              onClick={() => setShowSpeakerForm(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
