import { useNavigate } from 'react-router-dom';
import '../attendee-dashboard/ongoingLearning.scss';
import '../create-video-course/CourseCardWithOptions.scss';
const OdPopularEventCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          if (data?.type === 'VIDEO') {
            navigate(`/courses/${data?._id}`);
          } else if (data?.type === 'AUDIO') {
            navigate(`/courses/${data?._id}`);
          } else if (data?.type === 'PODCAST') {
            navigate(`/courses/${data?._id}`);
          } else {
            navigate(`/search-conference/${data?._id}`);
          }
        }}
        className="od-banner-container"
      >
        <img
          className="cd-banner"
          src={data?.banner?.[0].Location}
          alt="banner"
        />

        <div className="learningCard-text">
          {' '}
          <p className="learningCard-tag ">{`${
            data?.type === 'VIDEO'
              ? 'Video course'
              : data?.type === 'AUDIO'
              ? 'Audio course'
              : 'Conference'
          }`}</p>
        </div>
      </div>
      <div>
        <p className="learningCard-title">{data?.title}</p>
        <progress
          value={data?.watchPercentage}
          max="100"
          style={{ backgroundColor: '#08415c' }}
          className="ongoingLearning-progressBar"
        />
      </div>
    </>
  );
};

export default OdPopularEventCard;
