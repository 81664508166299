import { useState } from 'react';
import playIcon from '../../components/video-course/video-course-icons/play-button.png';
import docIcon from '../../components/video-course/video-course-icons/google-docs.png';
import queryIcon from '../../components/video-course/video-course-icons/question-sign.png';
import ModalX from '../modal/ModalX';
import ReactPlayer from 'react-player';
import '../courses-details/mediaContent.scss';
import DeleteIcon from '../icons/DeleteIcon';
import {
  getOneIncompletePodcast,
  getOneIncompletePodcastEpisode,
} from '../../utility/commonUtil';
import { useNavigate } from 'react-router-dom';
import Dialogue from '../dialogue/Dialogue';

import OpenEyeIcon from '../icons/OpenEyeIcon';
import EditIcon from '../icons/EditIcon';

const PodcastContent = ({
  data,
  sectionCount,
  episodeCount,
  preview,
  deleteEpisode,
  episodeId,
  setEpisodeId,
  openDeleteDialogue,
  setDeleteDialogue,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [video, setVideo] = useState({});

  const showPreviewHandler = async (list) => {
    setVideo(list);
    setShowPreviewModal(true);
  };

  const modalHandler = () => {
    setVideo({});
    setShowPreviewModal(false);
  };

  const imageTypeHandler = (list) => {
    if (list.type === 'doc') {
      return docIcon;
    } else if (list.type === 'query') {
      return queryIcon;
    } else {
      return playIcon;
    }
  };
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <p className="avenir-18-500 mb-16">Content</p>
        <div className="flex-vc-sb mb-12">
          <div
            className="avenir-16-500 flex-vc flex-vc-wrap"
            style={{ gap: 8, fontSize: 14 }}
          >
            <p>
              {sectionCount > 0
                ? sectionCount === 1
                  ? `• ${sectionCount} section`
                  : `• ${sectionCount} sections`
                : null}
            </p>{' '}
            <p>
              {episodeCount > 0
                ? episodeCount === 1
                  ? `• ${episodeCount} episode`
                  : `• ${episodeCount} episodes`
                : null}
            </p>{' '}
            <p>{data?.duration && `• total ${data?.duration}`} </p>
          </div>
          {preview && (
            <div style={{ float: 'right' }}>
              <button
                onClick={() => {
                  // navigate('/dashboard/add-pod-episode')

                  getOneIncompletePodcast(
                    data?._id,
                    navigate,
                    '/dashboard/add-pod-episode'
                  );
                }}
                className="pure-text-button"
              >
                Add Episode
              </button>
            </div>
          )}
        </div>
        {data?.episodes?.map((item, id) => {
          return (
            <div key={id} className="mc-details mt-4">
              <div className="mc-episode mb-6 mt-6" key={id}>
                <div className="mc-episode-left">
                  {item.preview && (
                    <img
                      src={imageTypeHandler(item)}
                      alt="play icon"
                      className="dark-play-icon"
                    />
                  )}
                </div>
                <div
                  onClick={() => {
                    return item.preview ? showPreviewHandler(item) : null;
                  }}
                  className="mc-episode-center"
                >
                  <p key={id} className={item.preview ? 'preview-button' : ''}>
                    {item.title}
                  </p>
                  {item?.preview && (
                    <>
                      {preview ? (
                        <OpenEyeIcon
                          className="icon-size mr-4"
                          fill={'#000000'}
                        />
                      ) : (
                        <p className={item.preview ? 'preview-button' : ''}>
                          Preview
                        </p>
                      )}
                    </>
                  )}
                </div>

                {preview && (
                  <div>
                    <i
                      className="mr-4"
                      onClick={() =>
                        getOneIncompletePodcastEpisode(
                          data?._id,
                          navigate,
                          item._id,
                          '/dashboard/add-pod-episode'
                        )
                      }
                    >
                      <EditIcon fill={'#000000'} />
                    </i>
                    <i
                      onClick={() => {
                        setEpisodeId(item?._id);
                        setDeleteDialogue(true);
                      }}
                    >
                      <DeleteIcon fill={'#000000'} />
                    </i>
                  </div>
                )}

                <div className="mc-episode-right">
                  <p>{item?.length}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {openDeleteDialogue && (
        <Dialogue
          msg="Are you sure you want to delete this episode?"
          title="Delete Episode"
          closeDialogue={() => {
            setDeleteDialogue(false);
          }}
          yesAction={() => deleteEpisode(data?._id, episodeId)}
        />
      )}
      {showPreviewModal && (
        <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
          <div className={` mb-40`}>
            <ReactPlayer
              controls={true}
              playing={true}
              url={video?.source?.Location}
              width="100%"
              height="100%"
            />
          </div>
        </ModalX>
      )}
    </div>
  );
};

export default PodcastContent;
