import { useState } from 'react';
import playIcon from '../../components/video-course/video-course-icons/play-button.png';
import docIcon from '../../components/video-course/video-course-icons/google-docs.png';
import queryIcon from '../../components/video-course/video-course-icons/question-sign.png';
import ModalX from '../modal/ModalX';
import ReactPlayer from 'react-player';
import './mediaContent.scss';
import confemyLogo from '../../assets/2.png';

const MediaContent = ({ data, sectionCount, episodeCount }) => {
  const [btnTxtState, setBtnTxtState] = useState('Expand all sections');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [video, setVideo] = useState({});

  let tabHandler = () => {
    let tags = document.getElementsByTagName('details');
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].open) {
        setBtnTxtState('Expand all sections');
        tags[i].open = false;
      } else {
        setBtnTxtState('Collapse all sections');
        tags[i].open = true;
      }
    }
  };

  const showPreviewHandler = async (list) => {
    setVideo(list);
    setShowPreviewModal(true);
  };

  const modalHandler = () => {
    setVideo({});
    setShowPreviewModal(false);
  };

  const imageTypeHandler = (list) => {
    if (list.type === 'doc') {
      return docIcon;
    } else if (list.type === 'query') {
      return queryIcon;
    } else {
      return playIcon;
    }
  };

  return (
    <div>
      <div>
        <p className="avenir-18-500 mb-16">Content</p>
        <div className="flex-vc-sb mb-12">
          <div
            className="avenir-16-500 flex-vc flex-vc-wrap"
            style={{ gap: 8, fontSize: 14 }}
          >
            <p>
              {sectionCount > 0
                ? sectionCount === 1
                  ? `• ${sectionCount} section`
                  : `• ${sectionCount} sections`
                : null}
            </p>{' '}
            <p>
              {episodeCount > 0
                ? episodeCount === 1
                  ? `• ${episodeCount} episode`
                  : `• ${episodeCount} episodes`
                : null}
            </p>{' '}
            <p>{data?.duration && `• total ${data?.duration}`} </p>{' '}
          </div>
          <button
            onClick={tabHandler}
            className="pure-text-button"
            style={{ fontSize: 14 }}
          >
            {btnTxtState}
          </button>
        </div>
        {data?.sections?.map((item, id) => {
          return (
            <details key={id} className="mc-details">
              <summary className="mc-summary">
                <span className="mc-summaryDiv">
                  <span className="mc-section-title">
                    {item?.section?.title}
                  </span>
                  <span className="mc-section-details">
                    <span>{item?.episodes.length} lectures</span>
                    <span>{item?.section?.totalDuration}</span>
                  </span>
                </span>
              </summary>
              {item.episodes?.map((list, id) => {
                return (
                  <div
                    onClick={() => {
                      return list.preview ? showPreviewHandler(list) : null;
                    }}
                    className="mc-episode mb-6 mt-6"
                    key={id}
                  >
                    <div className="mc-episode-left">
                      <img
                        src={imageTypeHandler(list)}
                        alt="play icon"
                        className="dark-play-icon"
                      />
                    </div>
                    <div className="mc-episode-center">
                      <p
                        key={id}
                        className={list.preview ? 'preview-button' : ''}
                      >
                        {list.title}
                      </p>
                      {list?.preview && (
                        <p className={list.preview ? 'preview-button' : ''}>
                          Preview
                        </p>
                      )}
                    </div>
                    <div className="mc-episode-right">
                      <p>{list?.length}</p>
                    </div>
                  </div>
                );
              })}
            </details>
          );
        })}
      </div>
      {showPreviewModal && (
        <div className="media-player-preview">
          <ModalX onDismiss={() => modalHandler()} open={showPreviewModal}>
            <div className="react-player-container ">
              <ReactPlayer
                controls={true}
                playing={true}
                url={video?.source?.Location}
                width={
                  data?.type === 'VIDEO' || data?.type === 'PODCAST'
                    ? '100%'
                    : '400px'
                }
                height={
                  data?.type === 'VIDEO' || data?.type === 'PODCAST'
                    ? '100%'
                    : '50px'
                }
              />
              <img
                src={confemyLogo}
                alt="Logo"
                className="react-player-video-logo"
              />
            </div>
          </ModalX>
        </div>
      )}
    </div>
  );
};

export default MediaContent;
